import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PersonAddIcon from "../../assets/img/person-add.svg";
import IconButton from "../../components/IconButton";
import PatientQueue from "../../components/PatientQueue";
import Popup from "../../components/Popup";
import ScreenLayout from "../../components/ScreenLayout";
import Spinner from "../../components/Spinner";
import TabView from "../../components/TabView";
import Toggle from "../../components/Toggle";
import useDoctorClinic from "../../hooks/useDoctorClinic";
import useTabs from "../../hooks/useTabs";
import ClinicDashboard from "./ClinicDashboard";
import CurrentPatient from "./CurrentPatient";
import Button from "../../components/Button";
import ReplaceIcon from "../../assets/img/replace.svg";
import TextInput from "../../components/TextInput";
import CardButton from "../../components/CardButton";
import NextIcon from "../../assets/img/next.svg";

const ManageClinic = () => {
  const tabs = useTabs([
    {
      label: "Current",
      id: "current",
    },
    {
      label: "History",
      id: "history",
    },
  ]);

  const mode = tabs.currentTab.id;

  const doctorClinic = useDoctorClinic(mode);

  const [waiveOpen, setWaiveOpen] = useState(false);
  const [confirming, setConfirming] = useState(false);
  const [startOpen, setStartOpen] = useState(false);
  const [endOpen, setEndOpen] = useState(false);

  const show = () => setWaiveOpen(true);
  const hide = () => {
    // doctorClinic.queue.waiveForm.resetForm();
    setWaiveOpen(false);
  };

  const [activePatient, setActivePatient] = useState(null);

  const navigate = useNavigate();

  return (
    <ScreenLayout title="Manage Clinic">
      <div className="flex flex-col gap-[1.5rem]">
        <div className="flex flex-row justify-between items-center">
          <h3 className="heading-3">Overview</h3>
          <Button
            text="Refresh"
            variant="btn-tertiary"
            size="btn-medium"
            onClick={doctorClinic.session.fetch}
          />
        </div>
        <ClinicDashboard active={doctorClinic.session.data?.isActive} />
      </div>

      {/* Start/End Session */}

      <div className="flex flex-col gap-[1.5rem] pb-[4rem]">
        <div className="flex flex-row justify-between">
          <h3 className="heading-3">Patients Queue</h3>
          <Toggle
            value={doctorClinic?.session?.data?.isActive}
            onClick={() => {
              if (!doctorClinic?.session?.data?.isActive) {
                setStartOpen(true);
              } else {
                setEndOpen(true);
              }
            }}
          />
        </div>

        {/* Current Patient */}

        <CurrentPatient
          session={doctorClinic?.session?.data}
          data={doctorClinic?.queue?.currentPatient}
          onWaive={show}
          nextPatient={doctorClinic.queue.nextPatient}
          isLast={!doctorClinic.queue.data?.length}
        />

        {/* Patient Queues */}

        {doctorClinic?.session?.data?.isActive && (
          <>
            <TabView
              tabs={{
                ...tabs,
                tabs: tabs.tabs.map((tab) => ({
                  ...tab,
                  count:
                    tab.id === "current"
                      ? doctorClinic?.queue?.data?.length
                      : tab.id === "history"
                      ? doctorClinic?.history?.data?.length
                      : undefined,
                })),
              }}
            />
            {doctorClinic?.queue?.loading ? (
              <Spinner />
            ) : (
              <PatientQueue
                patients={
                  tabs.currentTab.id === "current"
                    ? doctorClinic?.queue?.data
                    : doctorClinic?.history?.data || []
                }
                handleClick={
                  tabs.currentTab.id === "current" ? setActivePatient : () => {}
                }
              />
            )}
          </>
        )}
      </div>

      {doctorClinic?.session?.data?.isActive && (
        <div className="fixed right-[1.25rem] bottom-[1.25rem]">
          {/* Add Patient */}

          <IconButton
            iconSrc={PersonAddIcon}
            alt="add-patient"
            onClick={() => {
              navigate("/doctor/clinic/patient/create");
            }}
          />
        </div>
      )}
      {/* Waive Fee Popup */}
      <Popup visible={waiveOpen && !confirming} hide={hide} icon={ReplaceIcon}>
        <h4 className="heading-4 font-bold">Waive Fee</h4>

        <div className="w-full flex flex-row gap-[1rem] justify-between">
          <CardButton
            text="25%"
            onClick={() => {
              doctorClinic.setWaiveAmountByPercentage(25);
              doctorClinic.queue.waiveForm.setFieldTouched("waiveAmount", true);
            }}
            active={
              doctorClinic.queue.waiveForm.values.waiveAmount ===
              doctorClinic.getWaiveAmountByPercentage(25)
            }
          />
          <CardButton
            text="50%"
            onClick={() => {
              doctorClinic.setWaiveAmountByPercentage(50);
              doctorClinic.queue.waiveForm.setFieldTouched("waiveAmount", true);
            }}
            active={
              doctorClinic.queue.waiveForm.values.waiveAmount ===
              doctorClinic.getWaiveAmountByPercentage(50)
            }
          />
          <CardButton
            text="75%"
            onClick={() => {
              doctorClinic.setWaiveAmountByPercentage(75);
              doctorClinic.queue.waiveForm.setFieldTouched("waiveAmount", true);
            }}
            active={
              doctorClinic.queue.waiveForm.values.waiveAmount ===
              doctorClinic.getWaiveAmountByPercentage(75)
            }
          />
          <CardButton
            text="100%"
            onClick={() => {
              doctorClinic.setWaiveAmountByPercentage(100);
              doctorClinic.queue.waiveForm.setFieldTouched("waiveAmount", true);
            }}
            active={
              doctorClinic.queue.waiveForm.values.waiveAmount ===
              doctorClinic.getWaiveAmountByPercentage(100)
            }
          />
        </div>
        <div className="py-[12px] w-full">
          <TextInput
            placeholder="Enter waive amount"
            label="Waive Amount"
            isNumeric
            required
            name="waiveAmount"
            value={doctorClinic.queue.waiveForm.values.waiveAmount}
            error={doctorClinic.queue.waiveForm.errors.waiveAmount}
            setValue={doctorClinic.queue.waiveForm.handleChange("waiveAmount")}
            onBlur={doctorClinic.queue.waiveForm.handleBlur("waiveAmount")}
            touched={doctorClinic.queue.waiveForm.touched.waiveAmount}
            onFocus={() =>
              doctorClinic.queue.waiveForm.setFieldTouched("waiveAmount", true)
            }
          />
        </div>
        <div className="flex flex-row justify-row gap-[1rem] w-full">
          <Button
            text="Cancel"
            fullWidth
            size="btn-large"
            variant="btn-secondary"
            onClick={hide}
          />
          <Button
            text="Proceed"
            fullWidth
            size="btn-large"
            onClick={() => {
              if (
                doctorClinic.queue.waiveForm.dirty &&
                doctorClinic.queue.waiveForm.isValid
              ) {
                setConfirming(true);
              }
            }}
            disabled={!doctorClinic.queue.waiveForm.isValid}
          />
        </div>
      </Popup>
      {/* Waive Fee Confirmation */}
      <Popup
        visible={waiveOpen && confirming}
        hide={() => {
          setConfirming(false);
        }}
        icon={ReplaceIcon}
      >
        <h4 className="heading-4 font-bold">Confirm Waive</h4>

        <p className="body-small text-neutral-60">
          Are you sure you want to waive Rs.{" "}
          {doctorClinic.queue?.waiveForm.values.waiveAmount} for{" "}
          {doctorClinic.queue?.currentPatient?.PatientName}?
        </p>

        <div className="flex flex-row justify-row gap-[1rem] w-full">
          <Button
            text="Cancel"
            fullWidth
            size="btn-large"
            variant="btn-secondary"
            onClick={() => {
              setConfirming(false);
            }}
          />
          <Button
            text="Proceed"
            fullWidth
            size="btn-large"
            onClick={() => {
              doctorClinic.queue.waiveForm.handleSubmit();
              hide();
              setConfirming(false);
            }}
            disabled={!doctorClinic.queue.waiveForm.isValid}
          />
        </div>
      </Popup>

      {/* Request Send Next Patient */}
      <Popup
        visible={!!activePatient}
        hide={() => setActivePatient(null)}
        icon={NextIcon}
      >
        <h4 className="heading-4 font-bold">Send Patient</h4>
        <p className="body-small text-neutral-60">
          Are you sure you want to send in {activePatient?.PatientName}?
        </p>
        <div className="flex flex-row justify-row gap-[1rem] w-full">
          <Button
            text="Cancel"
            fullWidth
            size="btn-large"
            variant="btn-secondary"
            onClick={() => {
              setActivePatient(null);
            }}
          />
          <Button
            text="Yes"
            fullWidth
            size="btn-large"
            disabled={doctorClinic.queue.sendNext.loading}
            onClick={() =>
              doctorClinic.queue.sendNext.send(activePatient?.PatientID, () => {
                setActivePatient(null);
              })
            }
          />
        </div>
      </Popup>
      {/* Start session */}
      <Popup visible={startOpen} hide={() => setStartOpen(false)}>
        <h4 className="heading-4 font-bold">Start Session</h4>
        <p className="body-small text-neutral-60">
          Are you sure you want to start session now?
        </p>
        <div className="flex flex-row justify-row gap-[1rem] w-full">
          <Button
            text="Cancel"
            fullWidth
            size="btn-large"
            variant="btn-secondary"
            onClick={() => {
              setStartOpen(false);
            }}
          />
          <Button
            text="Yes"
            fullWidth
            size="btn-large"
            onClick={() => {
              doctorClinic.session.start();
              setStartOpen(false);
            }}
          />
        </div>
      </Popup>
      {/* End session */}
      <Popup visible={endOpen} hide={() => setEndOpen(false)}>
        <h4 className="heading-4 font-bold">End Session</h4>
        <p className="body-small text-neutral-60">
          Are you sure you want to end session now?
        </p>
        <div className="flex flex-row justify-row gap-[1rem] w-full">
          <Button
            text="Cancel"
            fullWidth
            size="btn-large"
            variant="btn-secondary"
            onClick={() => {
              setEndOpen(false);
            }}
          />
          <Button
            text="Yes"
            fullWidth
            size="btn-large"
            onClick={() => {
              doctorClinic.session.end();
              setEndOpen(false);
            }}
          />
        </div>
      </Popup>
    </ScreenLayout>
  );
};

export default ManageClinic;
