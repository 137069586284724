import React from "react";
import AuthLayout from "../../components/AuthLayout";
import Button from "../../components/Button";
import PhoneInput from "../../components/PhoneInput";
import TextInput from "../../components/TextInput";
import useSignin from "../../hooks/useSignin";

const Signin = () => {
  const signin = useSignin();

  return (
    <AuthLayout
      title="Sign In"
      bottomLink={{
        path: "/onboarding/signup",
        text: "Sign up",
      }}
    >
      <p className="body-small">Welcome Back</p>
      <PhoneInput
        name="cellNumber"
        value={signin.form.values.cellNumber}
        touched={signin.form.touched.cellNumber}
        error={signin.form.errors.cellNumber}
        setValue={signin.form.handleChange("cellNumber")}
        onBlur={signin.form.handleBlur("cellNumber")}
        onFocus={() => signin.form.setFieldTouched("cellNumber", true)}
        label="Mobile Number"
        required
      />
      <TextInput
        name="password"
        value={signin.form.values.password}
        error={signin.form.errors.password}
        touched={signin.form.touched.password}
        setValue={signin.form.handleChange("password")}
        onBlur={signin.form.handleBlur("password")}
        onFocus={() => signin.form.setFieldTouched("password", true)}
        placeholder="Enter Pin"
        required
        isHidden
        label="Pin"
        maxChars={6}
        isNumeric
      />
      {/* TO BE UNCOMMENTED */}
      {/* <Link className="body-small font-semibold text-primary-70 self-end w-fit">
        Reset Pin
      </Link> */}
      <div className="mt-[2rem] flex flex-col gap-[0.5rem]">
        <Button
          text="Continue"
          onClick={signin.form.handleSubmit}
          disabled={signin.loading || !signin.form.isValid}
        />
      </div>
    </AuthLayout>
  );
};

export default Signin;
