import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowRightIcon from "../../assets/img/arrow-right.svg";
import IconButton from "../../components/IconButton";
import ScreenLayout from "../../components/ScreenLayout";
import SearchBar from "../../components/SearchBar";
import Spinner from "../../components/Spinner";
import useGetPatients from "../../hooks/useGetPatients";
import useIsInViewport from "../../hooks/useIsInViewport";

const PatientListItem = ({ name, phone, gender, age }) => {
  const navigate = useNavigate();

  const displayCellNumber = `${phone.slice(0, 3)} ${phone.slice(
    3,
    6
  )} ${phone.slice(6, 9)} ${phone.slice(9, 13)}`;

  return (
    <div className="border-[1px] rounded-[0.5rem] border-primary-70 p-[1rem] flex flex-row justify-between items-center">
      <div className="flex flex-col gap-[0.5rem]">
        <h3 className="heading-3 text-neutral-90">{name}</h3>
        <p className="body-small neutral-60">{displayCellNumber}</p>
      </div>
      <div className="flex flex-col gap-[0.5rem] items-end">
        <IconButton
          iconSrc={ArrowRightIcon}
          variant="tertiary"
          size="icon-btn-medium"
          onClick={() => navigate(`/doctor/payments/${phone}`)}
        />
        <p className="body-small neutral-60">
          {(+age).toString().padStart(2, "0")} years / {gender}
        </p>
      </div>
    </div>
  );
};

const Patients = () => {
  const patients = useGetPatients();

  const [search, setSearch] = useState("");
  const bottomRef = useRef();

  const isInViewport = useIsInViewport(bottomRef);

  const reset = () => {
    setSearch("");
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      patients.search(search);
    }, 1000);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line
  }, [search]);

  useEffect(() => {
    if (isInViewport && !patients.loading && patients.isEnd) {
      patients.fetch();
    }
    // eslint-disable-next-line
  }, [isInViewport]);

  return (
    <ScreenLayout title="Patients">
      <SearchBar search={search} handleChange={handleChange} reset={reset} />
      <p className="body-micro text-neutral-60 font-normal my-[-1.5rem]">
        Found {patients?.totalRecords} results
      </p>
      {patients.loading && !patients.patients?.length ? (
        <Spinner />
      ) : (
        <div className="flex flex-col gap-[1rem]">
          {patients.patients?.map((patient, idx) => {
            return (
              <PatientListItem
                key={idx}
                name={patient.patientName}
                phone={patient.cellNumber}
                gender={patient.gender}
                age={patient.age}
              />
            );
          })}
          {patients.loading && <Spinner />}
        </div>
      )}
      <div ref={bottomRef} />
    </ScreenLayout>
  );
};

export default Patients;
