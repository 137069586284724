import React, { useState } from "react";
import InputContainer from "../InputContainer";
import InputError from "../InputError";
import InputLabel from "../InputLabel";

const TextInput = ({
  placeholder,
  value,
  setValue,
  label,
  required = true,
  error,
}) => {
  const [focused, setFocused] = useState(false);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  return (
    <div className="w-full">
      <InputLabel
        error={error}
        label={label}
        focused={focused}
        required={required}
      />
      <InputContainer focused={focused} small={true} error={error}>
        <textarea
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          className="input-area"
          placeholder={placeholder}
          value={value}
          onChange={handleChange}
          rows={6}
        />
      </InputContainer>
      {error && <InputError error={error} />}
    </div>
  );
};

export default TextInput;
