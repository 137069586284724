import React from "react";
import { createPortal } from "react-dom";

const Popup = ({ children, visible, icon, hide = () => {} }) => {
  return createPortal(
    <div
      className={`h-[100svh] w-screen bg-[#0008] fixed top-0 left-0 z-10 flex flex-col justify-end ${
        visible ? "" : "invisible"
      } transition-all`}
      onClick={hide}
    >
      <div
        className={`p-[1.25rem] bg-white rounded-t-[1rem] ${
          visible ? "" : "translate-y-full"
        } transition-transform max-h-[80vh] overflow-scroll`}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="w-[2.5rem] h-[0.1875rem] bg-neutral-40 rounded-full mx-auto mb-[1rem]" />
        <div className="flex flex-col gap-[1.5rem] items-center justify-between">
          {icon && (
            <div className="w-[3rem] h-[3rem] rounded-full bg-primary-0 flex flex-row justify-center items-center">
              <img src={icon} alt="icon" />
            </div>
          )}
          {children}
        </div>
      </div>
    </div>,
    document.getElementById("popup-root")
  );
};

export default Popup;
