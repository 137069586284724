import React, { useState } from "react";
import SearchIcon from "../../assets/img/search.svg";
import SearchInactiveIcon from "../../assets/img/search-inactive.svg";
import Button from "../Button";

const SearchBar = ({ search, handleChange, reset }) => {
  const [focused, setFocused] = useState(false);

  const active = !!search;

  return (
    <div className="flex flex-row gap-[0.25rem]">
      <div
        className={`p-[0.75rem_1rem] flex-1 border-[1px] transition-all ${
          focused
            ? "bg-white border-primary-70"
            : "bg-neutral-20 border-transparent"
        } flex flex-row gap-[0.5rem] rounded-full`}
      >
        {focused ? (
          <img src={SearchIcon} alt="search" />
        ) : (
          <img src={SearchInactiveIcon} alt="search-inactive" />
        )}
        <input
          className="bg-transparent flex-1 outline-none"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          value={search}
          onChange={handleChange}
          placeholder="Search"
        />
      </div>
      {active && (
        <Button
          text="Cancel"
          size="btn-medium"
          variant="btn-tertiary"
          onClick={reset}
        />
      )}
    </div>
  );
};

export default SearchBar;
