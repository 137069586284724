import React, { useEffect } from "react";
import CashIcon from "../../assets/img/cash.svg";
import CreditCardIcon from "../../assets/img/credit-card.svg";
import Button from "../../components/Button";
import ScreenLayout from "../../components/ScreenLayout";
import SelectInput from "../../components/SelectInput";
import CardMessage from "../../components/CardMessage";
import Spinner from "../../components/Spinner";
import usePackages from "../../hooks/usePackages";
import useSubscription from "../../hooks/useSubscription";
import CashBlueIcon from "../../assets/img/cash-blue.svg";

const Item = ({ children }) => {
  return (
    <div className="p-[1rem] border-[1px] border-neutral-20 rounded-[0.25rem] flex flex-col gap-[1rem]">
      {children}
    </div>
  );
};

const SubscriptionDetailItem = ({ label, value }) => {
  return (
    <div className="flex flex-col p-[1rem] border-[1px] border-neutral-20 rounded-[0.5rem] w-full">
      <p className="body-micro text-neutral-60">{label}</p>
      <p className="body-large text-neutral-90 font-semibold">{value}</p>
    </div>
  );
};

/*
function monthDiff(dateFrom, dateTo) {
  dateFrom = new Date(dateFrom);
  dateTo = new Date(dateTo);

  return (
    dateTo.getMonth() -
    dateFrom.getMonth() +
    12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
}
*/

const Subscription = () => {
  const packages = usePackages();
  const subscription = useSubscription();

  useEffect(() => {
    subscription.unpaidSubscriptions.fetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!subscription.unpaidSubscriptions.loading) {
      packages.fetch();
    }
    // eslint-disable-next-line
  }, [subscription.unpaidSubscriptions.loading]);

  return (
    <ScreenLayout title="Subscription">
      {subscription.unpaidSubscriptions.loading ? (
        <Spinner />
      ) : subscription.unpaidSubscriptions.data &&
        subscription.unpaidSubscriptions.data.count > 0 ? (
        <div className="flex flex-col flex-1 justify-between">
          <div className="flex-1 flex flex-col gap-[1rem]">
            <CardMessage
              iconSrc={CashBlueIcon}
              title="Cash Request Generated"
              message="Our Sales Rep. will contact you within 24 hours for Cash Collection"
            />
            <SubscriptionDetailItem
              label="Subscription Type"
              value={
                subscription.unpaidSubscriptions.data.rows[0].subscriptionNote
              }
            />
            <div className="flex flex-row gap-[1rem] justify-between items-center">
              <SubscriptionDetailItem
                label="Gross Amount"
                value={`Rs. ${Intl.NumberFormat("en-US").format(
                  subscription.unpaidSubscriptions.data.rows[0]
                    .subscriptionGrossAmount
                )}`}
              />
              <SubscriptionDetailItem
                label="Discount Amount"
                value={
                  subscription.unpaidSubscriptions.data.rows[0]
                    .subscriptionDiscountAmount
                }
              />
            </div>
          </div>
          <Button
            text="Delete Request"
            fullWidth
            size="btn-xlarge"
            variant="btn-secondary"
            type="btn-danger"
            onClick={() =>
              subscription.deleteSubscription.call(
                subscription.unpaidSubscriptions.data.rows[0].subscriptionID
              )
            }
            disabled={subscription.deleteSubscription.loading}
          />
        </div>
      ) : (
        <div className="flex flex-col gap-[1rem]">
          <Item>
            <div className="flex flex-row justify-between items-center gap-[1rem]">
              <img src={CashIcon} alt="cash" />
              <div className="flex flex-col gap[0.5rem] flex-1">
                <p className="flex-1 body-large text-neutral-90">Cash</p>
                <p className="flex-1 body-small text-neutral-60">
                  Available for limited time
                </p>
              </div>
              <input
                type="radio"
                checked={
                  subscription.addSubscription.form.values.paymentMethod ===
                  subscription.paymentMethods.CASH
                }
                value={subscription.paymentMethods.CASH}
                onChange={(e) =>
                  subscription.addSubscription.form.setFieldValue(
                    "paymentMethod",
                    e.target.value
                  )
                }
                name="paymentMethod"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
            {subscription.addSubscription.form.values.paymentMethod ===
              subscription.paymentMethods.CASH && (
              <>
                {packages.loading ? (
                  <Spinner />
                ) : packages.data && packages.data.packages.count ? (
                  <SelectInput
                    options={packages.data.packages.rows}
                    label="Package"
                    required
                    name="packageID"
                    value={subscription.addSubscription.form.values.packageID}
                    touched={
                      subscription.addSubscription.form.touched.packageID
                    }
                    valueGetter={(p) => p.packageID}
                    textGetter={(p) => p.packageName}
                    setValue={(value) => {
                      subscription.addSubscription.form.setFieldValue(
                        "packageID",
                        value
                      );
                      subscription.addSubscription.form.validateField(
                        "packageID"
                      );
                    }}
                    onBlur={subscription.addSubscription.form.handleBlur(
                      "packageID"
                    )}
                    onFocus={() =>
                      subscription.addSubscription.form.setFieldTouched(
                        "packageID",
                        true
                      )
                    }
                  />
                ) : null}
                <Button
                  text="Submit"
                  size="btn-small"
                  onClick={subscription.addSubscription.form.handleSubmit}
                  disabled={
                    subscription.addSubscription.loading ||
                    !packages.data ||
                    !packages.data.packages.count ||
                    !subscription.addSubscription.form.isValid
                  }
                />
              </>
            )}
          </Item>
          <Item>
            <div className="flex flex-row justify-between items-center gap-[1rem]">
              <img
                src={CreditCardIcon}
                alt="credit-card"
                className="w-[22px] h-[16px]"
              />
              <div className="flex flex-col gap[0.5rem] flex-1">
                <p className="flex-1 body-large text-neutral-90">
                  Credit/Debit Card
                </p>
                <p className="flex-1 body-small text-neutral-60">Coming Soon</p>
              </div>
              <input
                type="radio"
                disabled
                checked={
                  subscription.addSubscription.form.values.paymentMethod ===
                  subscription.paymentMethods.CARD
                }
                value={subscription.paymentMethods.CARD}
                onChange={(e) =>
                  subscription.addSubscription.form.setFieldValue(
                    "paymentMethod",
                    e.target.value
                  )
                }
                name="paymentMethod"
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
          </Item>
          <Item>
            <div className="flex flex-row justify-between items-center gap-[1rem]">
              <img src={CreditCardIcon} alt="credit-card" />
              <div className="flex flex-col gap[0.5rem] flex-1">
                <p className="flex-1 body-large text-neutral-90">JazzCash</p>
                <p className="flex-1 body-small text-neutral-60">Coming Soon</p>
              </div>
              <input
                type="radio"
                checked={
                  subscription.addSubscription.form.values.paymentMethod ===
                  subscription.paymentMethods.JAZZCASH
                }
                value={subscription.paymentMethods.JAZZCASH}
                onChange={(e) =>
                  subscription.addSubscription.form.setFieldValue(
                    "paymentMethod",
                    e.target.value
                  )
                }
                name="paymentMethod"
                disabled
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
          </Item>
          <Item>
            <div className="flex flex-row justify-between items-center gap-[1rem]">
              <img src={CreditCardIcon} alt="credit-card" />
              <div className="flex flex-col gap[0.5rem] flex-1">
                <p className="flex-1 body-large text-neutral-90">Easypaisa</p>
                <p className="flex-1 body-small text-neutral-60">Coming Soon</p>
              </div>
              <input
                type="radio"
                checked={
                  subscription.addSubscription.form.values.paymentMethod ===
                  subscription.paymentMethods.EASYPAISA
                }
                value={subscription.paymentMethods.EASYPAISA}
                onChange={(e) =>
                  subscription.addSubscription.form.setFieldValue(
                    "paymentMethod",
                    e.target.value
                  )
                }
                name="paymentMethod"
                disabled
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
            </div>
          </Item>
        </div>
      )}
    </ScreenLayout>
  );
};

export default Subscription;
