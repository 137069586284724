import { useEffect } from "react";
import useInternetAvailability from "../../hooks/useInternetAvailability";
import { useSnackbar } from "../../context/Snackbar.context";
import Spinner from "../Spinner";

const InternetAvailability = ({ children }) => {
  const isOnline = useInternetAvailability();
  const snackbar = useSnackbar();

  useEffect(() => {
    if (!isOnline) {
      snackbar.show("error", "Internet unavailable");
    }
    // eslint-disable-next-line
  }, [isOnline]);

  return (
    <>
      {children}
      {!isOnline && (
        <div className="fixed top-0 left-0 w-full h-[100svh] bg-[#0008] z-30 flex flex-row items-center justify-center">
          <Spinner />
        </div>
      )}
    </>
  );
};

export default InternetAvailability;
