import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useApi } from "../api";
import { useSnackbar } from "../context/Snackbar.context";
import getErrorMessage from "../utils/getErrorMessage";
import { PhoneRegex } from "../utils/regex";

const useSignin = () => {
  const api = useApi();
  const snackbar = useSnackbar();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const form = useFormik({
    initialValues: {
      cellNumber: "",
      password: "",
    },
    isInitialValid: false,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      cellNumber: Yup.string()
        .required("Required")
        .matches(PhoneRegex, { message: "Invalid cell number" }),
      password: Yup.string()
        .required("Required")
        .matches(/^\d+$/, { message: "Must be all numbers" }),
    }),
    onSubmit: ({ cellNumber, password }, { resetForm }) => {
      setLoading(true);
      api
        .logIn({
          countryCode: "+92",
          cellNumber,
          password,
        })
        .then((res) => {
          setSuccess(true);
          resetForm();
          snackbar.show("success", "Successfully logged in");
          navigate("/doctor");
        })
        .catch((error) => {
          console.log(error);
          const message = getErrorMessage(error);
          snackbar.show("error", message);
          setError(message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return {
    loading,
    error,
    success,
    form,
  };
};

export default useSignin;
