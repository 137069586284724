const CurrentPatient = ({ data, session }) => {
  return (
    <div className="rounded-[0.875rem] border-primary-60 border-[1px] bg-primary-0 p-[1rem] flex flex-row justify-between items-center relative">
      {!session?.isActive ? (
        <div className="flex flex-col gap-[0.25rem] justify-center items-start">
          <h4 className="heading-4 text-neutral-90 font-bold">
            No active session
          </h4>
        </div>
      ) : !data ? (
        <>
          <div className="flex flex-col gap-[0.25rem] justify-center items-start">
            <h4 className="heading-4 text-neutral-90 font-bold">
              No current patient
            </h4>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col gap-[0.25rem] justify-center items-start">
            <p className="body-small text-neutral-60 font-semibold">
              Token Number: {data.token_number.toString().padStart(2, "0")}
            </p>
            <h4 className="heading-4 text-neutral-90 font-bold">
              {data.PatientName}
            </h4>
            <p className="body-small text-neutral-60 font-semibold">
              {data.Age} years, {data.Gender}
            </p>
          </div>
          <div className="w-[1px] bg-primary-70 h-[full]" />
          <div className="flex flex-col gap-[0.25rem] justify-center items-center">
            <p className="body-small text-neutral-60 font-semibold">
              Waived Fee
            </p>
            <p className="body-large text-neutral-90 font-semibold ">
              {data.isFeeWaived
                ? `Rs. ${new Intl.NumberFormat("en-US").format(
                    data.WaivedFeeAmount
                  )}`
                : "-"}
            </p>
          </div>
        </>
      )}
    </div>
  );
};

export default CurrentPatient;
