import React from "react";

const Check = ({ value, setValue, name }) => {
  return (
    <>
      <input
        type="checkbox"
        name={name}
        className="w-4 h-4 rounded outline-primary-70"
        onChange={() => {
          setValue(!value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setValue(!value);
          }
        }}
        checked={value}
      />
    </>
  );
};

export default Check;
