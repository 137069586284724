import React from "react";

const InputLabel = ({ label, focused = false, required = false, error }) => {
  const classes = ["input-label"];

  if (required) {
    classes.push("input-label-required");
  } else {
    classes.push("input-label-optional");
  }

  if (error) {
    classes.push("input-label-error");
  } else if (focused) {
    classes.push("input-label-active");
  }

  return <label className={classes.join(" ")}>{label}</label>;
};

export default InputLabel;
