import React from "react";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "../../assets/img/delete.svg";
import LinkIcon from "../../assets/img/link.svg";
import Avatar from "../../components/Avatar";
import Button from "../../components/Button";
import ScreenLayout from "../../components/ScreenLayout";
import SettingCard from "../../components/SettingCard";
import { reset } from "../../redux/actions";

const LinkedAccountItem = ({ name, linked = false }) => {
  return (
    <div className="flex flex-row gap-[0.75rem] items-center justify-between rounded-[0.5rem] p-[1rem] bg-white shadow-lg">
      <p className="body-large font-semibold text-neutral-90">{name}</p>
      {/* {linked ? (
        <button className="p-[0.5rem]">
          <img src={DeleteIcon} alt="delete" />
        </button>
      ) : (
        <button className="p-[0.5rem]">
          <img src={LinkIcon} alt="link" />
        </button>
      )} */}
    </div>
  );
};

const PAAccountSettings = () => {
  const dispatch = useDispatch();
  const { userName, clinicDetails } = useSelector((state) => state.userReducer);
  return (
    <ScreenLayout title="Account Settings">
      <div className="flex-1 px-[1.25rem] mx-[-1.25rem] flex flex-col gap-[2.25rem]">
        <div className="flex flex-col gap-[0.75rem]">
          <SettingCard left={<Avatar text={userName} />} title={userName} />
          {/* <SettingCard
            left={<CircularIcon src={CallIcon} />}
            title="Phone"
            description="+92 123 4567"
          />
          <SettingCard
            left={<CircularIcon src={LockIcon} />}
            title="Password"
            description="Reset Password"
          /> */}
        </div>
        <h3 className="heading-3">Linked Accounts</h3>
        <div className="flex flex-col gap-[0.75rem]">
          {clinicDetails.map((clinic, idx) => {
            return (
              <LinkedAccountItem key={idx} name={clinic.clinicName} linked />
            );
          })}

          {/* <h5 className="heading-5 font-bold text-neutral-90">Link Requests</h5>
          <LinkedAccountItem name="Dr. Abbas Rizvi" /> */}
        </div>
      </div>
      <Button
        text="Log out"
        fullWidth
        size="btn-xlarge"
        variant="btn-secondary"
        type="btn-danger"
        onClick={() => {
          dispatch(reset());
        }}
      />
    </ScreenLayout>
  );
};

export default PAAccountSettings;
