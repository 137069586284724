import React, { useState } from "react";
import AuthLayout from "../../components/AuthLayout";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { useOnboarding } from "../../context/Onboarding.context";
import Check from "../../components/Check";
import InputError from "../../components/InputError";
import Popup from "../../components/Popup";
import moment from "moment";

const SetPassword = () => {
  const { password, onBoardingRole } = useOnboarding();

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const show = (e) => {
    e.stopPropagation();
    setShowTermsAndConditions(true);
  };
  const hide = () => {
    setShowTermsAndConditions(false);
  };

  return (
    <AuthLayout
      title="Set Pin Code"
      withoutLogo
      bottomLink={{
        goBack: true,
      }}
    >
      <TextInput
        placeholder="Set Pin Code"
        isHidden
        label="Pin"
        isNumeric
        required
        name="password"
        value={password.form.values.password}
        touched={password.form.touched.password}
        setValue={password.form.handleChange("password")}
        onBlur={password.form.handleBlur("password")}
        onFocus={() => password.form.setFieldTouched("password", true)}
        error={password.form.errors.password}
        helperText="Pin must comprise 4 to 6 digits"
        maxChars={6}
      />
      <TextInput
        placeholder="Confirm Pin"
        isHidden
        required
        label="Confirm Pin"
        name="confirmPassword"
        value={password.form.values.confirmPassword}
        touched={password.form.touched.confirmPassword}
        setValue={password.form.handleChange("confirmPassword")}
        onBlur={password.form.handleBlur("confirmPassword")}
        onFocus={() => password.form.setFieldTouched("confirmPassword", true)}
        error={password.form.errors.confirmPassword}
      />
      {onBoardingRole === "PA" && (
        <>
          <div className="flex flex-row gap-[8px] items-center">
            <Check
              name="termsAccepted"
              value={password.form.values.termsAccepted}
              error={password.form.errors.termsAccepted}
              touched={password.form.touched.termsAccepted}
              setValue={(value) => {
                password.form.setFieldValue("termsAccepted", value);
              }}
              onBlur={password.form.handleBlur("termsAccepted")}
              onFocus={() =>
                password.form.setFieldTouched("termsAccepted", true)
              }
            />
            <p
              className="body-small font-medium text-neutral-40"
              onClick={() => {
                password.form.setFieldValue(
                  "termsAccepted",
                  !password.form.values.termsAccepted
                );
              }}
            >
              I have read and accepted{" "}
              <button onClick={show} className="text-primary-70 font-semibold">
                Terms and Conditions
              </button>
            </p>
          </div>
          {password.form.errors.termsAccepted &&
            password.form.touched.termsAccepted && (
              <InputError error={password.form.errors.termsAccepted} />
            )}
        </>
      )}
      <div className="mt-[2rem] flex flex-col gap-[0.5rem]">
        <Button
          text={onBoardingRole === "PA" ? "Submit" : "Next"}
          onClick={password.form.handleSubmit}
          disabled={password.loading || !password.form.isValid}
        />
      </div>
      <Popup visible={showTermsAndConditions} hide={hide}>
        <h4 className="heading-4 font-bold">Terms and Conditions</h4>
        <p className="body-small font-bold">
          Effective Date & Time: {moment(new Date()).format("LLL")}
        </p>
        <p className="body-small">
          This My Clinic App - Personal Assistant (PA) Terms of Use
          ("Agreement") is entered into between [My Clinic App] ("QP," "Quantum
          Paradigm (Private) Limited," or "First Party" or “our” or “us” or
          “we”) and you ("Personal Assistant (PA) Name" or "you" or “your” or
          “yours” or “sub user”) upon your sign-up and use of the My Clinic App
          (the "App"). This Agreement governs your access to and use of the App
          as a Personal Assistant (PA). By signing up and using the App, you
          acknowledge that you have read, understood, and agree to be bound by
          the terms and conditions of this Agreement.
        </p>
        <p className="body-small font-bold">1. App Access and Use</p>
        <p className="body-small ">
          <span className="font-bold">(a) Eligibility:</span> By signing up and
          using the App, you represent and warrant that you are a Personal
          Assistant (PA) to [INSERT Practitioner Name] and you are providing
          services through the App.
        </p>
        <p className="body-small ">
          <span className="font-bold">(b) License Grant:</span> Subject to your
          compliance with the terms of this Agreement, we grant you a limited,
          non-exclusive, non-transferable, and revocable license to access and
          use the App solely for the purpose of providing a means to streamline
          your efforts as a Personal Assistant (PA) to [INSERT Practitioner
          Name] through the App.
        </p>
        <p className="body-small ">
          <span className="font-bold">(c)Sub User Account:</span> You are a sub
          user added by [INSERT Practitioner Name] to become their Personal
          Assistant (PA) through this App. You are required to create a sub user
          account to access the App. You agree to provide accurate, complete,
          and up-to-date information during the registration process and to keep
          your account credentials confidential. You are solely responsible for
          all activities that occur under your account.
        </p>
        <p className="body-small ">
          <span className="font-bold">
            (d) Patient Registration & Sequence Management:
          </span>{" "}
          Through the My Clinic App, you may register patients of [INSERT
          Practitioner Name], manage their sequence and also monitor their
          clinic cash collection. You agree to provide services in accordance
          with applicable laws, regulations, and professional standards. You
          acknowledge that you are solely responsible for all entries and
          interactions of patients, and we shall not be liable for any & all
          acts or omissions by you.
        </p>
        <p className="body-small ">
          <span className="font-bold">(e) Compliance with Laws:</span>You agree
          to comply with all applicable laws, rules, and regulations governing
          the provision of medical services, patient privacy, and data
          protection, including but not limited to any local laws and
          regulations.
        </p>
        <p className="body-small ">
          <span className="font-bold">(f) Prohibited Activities:</span> You
          shall not use the App for any unlawful, fraudulent, or malicious
          purposes, including but not limited to unauthorized access, data
          breaches, or dissemination of patient information. You shall not
          engage in any activity that may harm our reputation or disrupt the
          operation of the App. In breaching this clause you indemnify us and
          you claim sole responsibility of any and all consequences of the same.
        </p>
        <p className="body-small font-bold">2. Intellectual Property</p>
        <p className="body-small ">
          <span className="font-bold">(a) Ownership:</span> The App and all
          intellectual property rights associated with it are and shall remain
          our exclusive property. This Agreement does not grant you any
          ownership rights or licenses in the App, except as expressly provided
          herein.
        </p>
        <p className="body-small ">
          <span className="font-bold">(b) Feedback:</span> If you provide any
          suggestions, ideas, or feedback regarding the App ("Feedback"), you
          hereby grant the Clinic a non-exclusive, royalty-free, perpetual,
          irrevocable, and worldwide license to use, reproduce, modify,
          distribute, and exploit such Feedback for any purpose without any
          obligation to you.
        </p>
        <p className="body-small font-bold">3. Data Privacy and Security</p>
        <p className="body-small ">
          <span className="font-bold">(a) Data Collection and Use:</span> By
          using the My Clinic App, you acknowledge and agree that we may
          collect, store, process, and use your personal information and patient
          data in accordance with our Privacy Policy. You are responsible for
          obtaining patient consent and complying with applicable privacy laws
          and regulations regarding the collection and use of patient data.
        </p>
        <p className="body-small ">
          <span className="font-bold">(b) Security:</span> We employ reasonable
          security measures to protect your personal information and patient
          data. However, you acknowledge that no data transmission over the
          internet or storage system can be guaranteed to be 100% secure, and we
          shall not be liable for any unauthorized access, loss, or alteration
          of data.
        </p>
        <p className="body-small font-bold">4. Termination</p>
        <p className="body-small ">
          <span className="font-bold">(a) Termination by Either Party:</span>{" "}
          Either party may terminate this Agreement at any time, with or without
          cause, by providing 30 day written notice to the other.
        </p>
        <p className="body-small font-bold">
          5. Clinical & Bio Data Collection
        </p>
        <p className="body-small ">
          <span className="font-bold">(a) Patient Data Collection:</span> The
          App may collect and store clinical and bio data of patients ("Patient
          Data") as part of the medical consultation process. Patient Data may
          include but is not limited to medical history, diagnostic reports,
          treatment plans, and vital signs. You acknowledge and agree that the
          collection and use of Patient Data shall be in compliance with
          applicable privacy laws and regulations and the Clinic's Privacy
          Policy.
        </p>
        <p className="body-small ">
          <span className="font-bold">(b) Consent and Confidentiality:</span>{" "}
          You are responsible for obtaining patient consent for the collection,
          use, and disclosure of their Patient Data through the My Clinic App.
          You shall treat all Patient Data as confidential and shall not
          disclose it to any third party without proper authorization, unless
          required by law or as necessary to provide medical services to the
          patient.
        </p>
        <p className="body-small ">
          <span className="font-bold">(c) Data Accuracy and Integrity:</span>{" "}
          You agree to ensure the accuracy, completeness, and integrity of the
          Patient Data you enter or access through the App. You shall promptly
          update any inaccuracies or discrepancies in the Patient Data and
          notify us of any potential data breaches or security incidents.
        </p>
        <p className="body-small font-bold">6. Ownership Rights</p>
        <p className="body-small ">
          <span className="font-bold">(a) App Ownership:</span> The App,
          including its software, design, features, and functionality, is owned
          by Quantum Paradigm (Private) Limited and is protected by copyright,
          trademark, and other intellectual property laws. You acknowledge that
          we retain all rights, title, and interest in and to the App, including
          any updates, enhancements, or modifications thereto.
        </p>
        <p className="body-small ">
          <span className="font-bold">(b) License Restrictions:</span> Except as
          expressly permitted under this Agreement, you shall not copy, modify,
          distribute, reverse engineer, decompile, or create derivative works of
          the App. You shall not remove, alter, or obscure any copyright,
          trademark, or proprietary notices displayed within the App.
        </p>
        <p className="body-small ">
          <span className="font-bold">(c) Feedback Ownership:</span> Any
          feedback, suggestions, or ideas provided by you regarding the App
          shall be considered our exclusive property. We may use, implement, or
          modify such feedback without any obligation to you, and you hereby
          waive any rights or claims in connection with such feedback.
        </p>
        <p className="body-small font-bold">7. Indemnification</p>
        <p className="body-small">
          You agree to indemnify, defend, and hold harmless Quantum Paradigm
          (Private) Limited, its affiliates, officers, directors, employees, and
          agents from any claims, liabilities, damages, losses, or expenses
          (including reasonable attorneys' fees) arising out of or in connection
          with your use of the App, your provision of medical services, or any
          violation of this Agreement or applicable laws.
        </p>
        <p className="body-small font-bold">
          8. Governing Law and Dispute Resolution
        </p>
        <p className="body-small">
          This Agreement shall be governed by and construed in accordance with
          the laws of Pakistan.
        </p>
        <p className="body-small font-bold">9. Amendments</p>
        <p className="body-small">
          The Clinic reserves the right to modify or update this Agreement at
          any time by providing notice through the App. Your continued use of
          the App after such modifications shall constitute your acceptance of
          the updated Agreement.
        </p>
        <Button text="Okay" fullWidth size="btn-large" onClick={hide} />
      </Popup>
    </AuthLayout>
  );
};

export default SetPassword;
