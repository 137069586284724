import React, { useRef, useState } from "react";
import InputError from "../InputError";

const DigitInput = ({ value, active, valid, error }) => {
  return (
    <input
      disabled
      value={value}
      className={`w-[2.25rem] h-[2.25rem] flex flex-row justify-center items-center outline-none text-center body-small input-container ${
        error
          ? "input-container-error"
          : valid
          ? "input-container-success"
          : active
          ? "input-container-active"
          : ""
      }`}
    />
  );
};

const OTPInput = ({ error, otp, setOtp }) => {
  const [focused, setFocused] = useState(true);

  const otpRef = useRef();
  const handleChange = (e) => {
    const newValue = e.target.value;
    if (/^\d{0,6}$/.test(newValue)) {
      setOtp(newValue);
    }
  };

  const activeIndex = Math.max(otp.length - 1, 0);

  return (
    <>
      <div
        className="w-full flex flex-row gap-[0.5rem] justify-between relative"
        onClick={() => {
          otpRef.current?.focus();
        }}
      >
        {[...Array(6)].map((_, idx) => {
          return (
            <DigitInput
              key={idx}
              value={otp.charAt(idx) || ""}
              active={focused && idx === activeIndex}
              valid={otp.length === 6}
              error={error}
            />
          );
        })}
        <input
          value={otp}
          onChange={handleChange}
          autoFocus
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          ref={otpRef}
          className="absolute w-full h-full top-0 left-0 z-1 opacity-0"
        />
      </div>
      {error && <InputError error="Incorrect OTP" />}
    </>
  );
};

export default OTPInput;
