import axios from "axios";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  generateOtpToken,
  setAccessToken,
  setClinic,
  setLoggedIn,
  setOnBoardingCell,
  setOnBoardingRole,
  setOtp,
  setPADetails,
  setRefreshToken,
  setRole,
  setStates,
  setUserName,
  verifyOtpToken,
} from "../redux/actions";
import getDoctorClinicId from "../utils/getDoctorClinicId";

const apiInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER}/api/v1`,
});

const applyTokenInterceptor = (token) => {
  apiInstance.interceptors.request.use((config) => {
    if (token && !config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  }, console.log);
};

export const useApi = () => {
  const dispatch = useDispatch();
  const { accessToken, refreshToken, VotpToken, clinicDetails, fcmToken } =
    useSelector((state) => state.userReducer);

  const doctorClinicID = getDoctorClinicId(clinicDetails);

  useEffect(() => {
    applyTokenInterceptor(accessToken);
  }, [accessToken]);

  const generateOtp = async ({ countryCode, cellNumber }) => {
    const response = await apiInstance.post("/auth/generate-otp", {
      countryCode,
      cellNumber,
    });

    dispatch(setOnBoardingRole(response.data.body.role));
    dispatch(setOnBoardingCell(cellNumber));
    dispatch(generateOtpToken(response.data.body.accessToken));
    dispatch(setOtp(response.data.body.otp));

    verifyOtp({
      otpCode: response.data.body.otp,
      auth: response.data.body.accessToken,
    });

    return response;
  };

  const verifyOtp = async ({ otpCode, auth }) => {
    const response = await apiInstance.post(
      "/auth/verify-otp",
      { otpCode },
      {
        headers: {
          Authorization: `Bearer ${auth}`,
        },
      }
    );

    dispatch(setOnBoardingRole(response.data.body.role));
    dispatch(setStates(response.data.body.states));
    dispatch(verifyOtpToken(response.data.body.accessToken));

    return response;
  };

  const getSession = async ({ clinicID } = {}) => {
    const response = await apiInstance.post("/clinic/get-session", {
      clinicID: clinicID || doctorClinicID,
    });

    return response;
  };

  const startSession = async ({ clinicID } = {}) => {
    const response = await apiInstance.post("/clinic/start-session", {
      clinicID: clinicID || doctorClinicID,
    });

    return response;
  };

  const endSession = async () => {
    const response = await apiInstance.post("/clinic/end-session", {
      clinicID: doctorClinicID,
    });

    return response;
  };

  const addPatientQueue = async ({ clinicID, patientID }) => {
    const response = await apiInstance.post("/clinic/add-patient-queue", {
      clinicID: clinicID || doctorClinicID,
      patientID,
    });

    return response;
  };

  const getPatientQueue = async ({ clinicID } = {}) => {
    const response = await apiInstance.post("/clinic/get-patient-queue", {
      clinicID: clinicID || doctorClinicID,
    });

    return response;
  };

  const getPatientQueueChecked = async ({ clinicID } = {}) => {
    const response = await apiInstance.post(
      "/clinic/get-patient-queue-checked",
      { clinicID: clinicID || doctorClinicID }
    );

    return response;
  };

  const sendNextPatient = async ({ patientID, clinicID }) => {
    const response = await apiInstance.post("/clinic/send-next-patient", {
      clinicID,
      patientID,
    });

    return response;
  };

  const deletePatientFromQueue = async ({ clinicID, queueID }) => {
    const response = await apiInstance.post(
      "/clinic/delete-from-patient-queue",
      { clinicID: clinicID || doctorClinicID, queueID }
    );

    return response;
  };

  const refresh = async () => {
    if (accessToken && refreshToken) {
      const response = await apiInstance.post("/auth/refresh-token", {
        refreshToken,
      });
      dispatch(setAccessToken(response.data.body.accessToken));
      return response;
    } else {
      throw new Error("Out of session");
    }
  };

  const logIn = async ({ countryCode, cellNumber, password }) => {
    const response = await apiInstance.post("/auth/login", {
      countryCode,
      cellNumber,
      password,
      deviceToken: fcmToken,
    });
    dispatch(setLoggedIn(true));
    dispatch(setRefreshToken(response.data.body.refreshToken));
    dispatch(setAccessToken(response.data.body.accessToken));
    dispatch(setRole(response.data.body.role));
    dispatch(setUserName(response.data.body.userName));
    dispatch(setClinic(response.data.body.clinicData));
    return response;
  };

  const registerDoctor = async ({
    password,
    fullName,
    gender,
    dateOfBirth,
    clinicName,
    clinicAddress,
    clinicState,
    clinicCity,
    speciality,
    feeAmount,
    secondryCountryCode,
    secondryCellNumber,
  }) => {
    const response = await apiInstance.post(
      "/auth/register-doctor",
      {
        password,
        fullName,
        gender,
        dateOfBirth,
        clinicName,
        clinicAddress,
        clinicState,
        clinicCity,
        speciality,
        feeAmount,
        secondryCountryCode,
        secondryCellNumber,
      },
      {
        headers: {
          Authorization: `Bearer ${VotpToken}`,
        },
      }
    );
    return response;
  };

  const registerPA = async ({ password }) => {
    const response = await apiInstance.post(
      "/auth/register-pa",
      { password },
      {
        headers: {
          Authorization: `Bearer ${VotpToken}`,
        },
      }
    );
    return response;
  };

  const deletePA = async () => {
    const response = await apiInstance.post("/doctor/delete-pa", {
      clinicID: doctorClinicID,
    });
    dispatch(
      setPADetails({ paExist: false, cellNumber: null, fullName: null })
    );
    return response;
  };

  const registerPAByDoctor = async ({
    countryCode,
    cellNumber,
    fullName,
    gender,
  }) => {
    const response = await apiInstance.post("/doctor/register-pa", {
      clinicID: doctorClinicID,
      countryCode,
      cellNumber,
      fullName,
      gender,
    });
    dispatch(setPADetails({ paExist: true, cellNumber, fullName }));
    return response;
  };

  const replacePAByDoctor = async ({
    countryCode,
    cellNumber,
    fullName,
    gender,
  }) => {
    const response = await apiInstance.post("/doctor/replace-pa", {
      clinicID: doctorClinicID,
      countryCode,
      cellNumber,
      fullName,
      gender,
    });
    return response;
  };

  const getDoctorDashboard = async () => {
    const response = await apiInstance.post("/doctor/get-doctor-dashboard", {
      clinicID: doctorClinicID,
    });

    return response;
  };

  const getPackages = async () => {
    const response = await apiInstance.post("/doctor/get-packages");

    return response;
  };

  const getClinicDashboard = async () => {
    const response = await apiInstance.post("/doctor/get-clinic-dashboard", {
      clinicID: doctorClinicID,
    });

    return response;
  };

  const requestNextPatientDoctor = async ({ patientID }) => {
    const response = await apiInstance.post("/doctor/request-next-patient", {
      clinicID: doctorClinicID,
      patientID,
    });

    return response;
  };

  const waivePatientFee = async ({ patientID, waiveAmount }) => {
    const response = await apiInstance.post("/doctor/waive-patient-fee", {
      clinicID: doctorClinicID,
      patientID,
      waiveAmount,
    });

    return response;
  };

  const addSubscription = async ({ packageID, paymentMethod }) => {
    const response = await apiInstance.post("/doctor/add-subscription", {
      packageID,
      paymentMethod,
    });

    return response;
  };

  const deleteSubscription = async ({ subscriptionID }) => {
    const response = await apiInstance.post("/doctor/delete-subscription", {
      subscriptionID,
    });

    return response;
  };

  const getUnPaidSubscription = async () => {
    const response = await apiInstance.post("/doctor/get-unpaid-subscriptions");

    return response;
  };

  const getPaidSubscription = async () => {
    const response = await apiInstance.post("/doctor/get-paid-subscriptions");

    return response;
  };

  const getStatistics = async ({ statisticType, startDate, endDate }) => {
    const response = await apiInstance.post("/doctor/get-statistics", {
      statisticType,
      startDate,
      endDate,
    });

    return response;
  };

  const getGraphs = async ({ graphType }) => {
    const response = await apiInstance.post("/doctor/get-graphs", {
      graphType,
    });

    return response;
  };

  const addPatient = async ({
    clinicID,
    countryCode,
    cellNumber,
    patientName,
    gender,
    age,
  }) => {
    const response = await apiInstance.post("/patient/register-patient", {
      clinicID: clinicID || doctorClinicID,
      countryCode,
      cellNumber,
      patientName,
      gender,
      age,
    });
    return response;
  };

  const registerAddPatient = async ({
    clinicID,
    countryCode,
    cellNumber,
    patientName,
    gender,
    age,
  }) => {
    const response = await apiInstance.post(
      "/patient/register-and-add-patient",
      {
        clinicID: clinicID || doctorClinicID,
        countryCode,
        cellNumber,
        patientName,
        gender,
        age,
      }
    );
    return response;
  };

  const getPatients = async ({
    clinicID,
    pageNumber,
    pageSize,
    searchValue = "",
  }) => {
    const response = await apiInstance.post("/patient/get-patients", {
      clinicID: clinicID || doctorClinicID,
      pageNumber,
      pageSize,
      searchValue,
    });
    return response;
  };

  const getPaymentHistory = async ({ cellNumber }) => {
    const response = await apiInstance.post(
      "/patient/get-patient-payment-history",
      {
        clinicID: doctorClinicID,
        cellNumber,
      }
    );
    return response;
  };
  const doctorsendNextPatient = async ({ newPatientID, clinicID }) => {
    const response = await apiInstance.post(
      "/doctor/request-send-next-patient",
      {
        clinicID: clinicID || doctorClinicID,
        newPatientID,
      }
    );
    return response;
  };

  const getPA = async () => {
    const response = await apiInstance.post("/doctor/get-pa", {
      clinicID: doctorClinicID,
    });
    const paDetails = response.data.body;
    dispatch(setPADetails(paDetails));
    return response;
  };

  const getCities = async (clinicState) => {
    const response = await apiInstance.post(
      "/auth/get-cities",
      {
        clinicState,
      },
      {
        headers: {
          Authorization: `Bearer ${VotpToken}`,
        },
      }
    );
    return response;
  };

  const getNotifications = async () => {
    const response = await apiInstance.post("/clinic/get-notifications");
    return response;
  };

  const clinicEditFee = async ({ feeAmount }) => {
    const response = await apiInstance.post("/profile/update-clinic-fees", {
      feeAmount,
    });
    return response;
  };

  const updatePassword = async ({ password }) => {
    const response = await apiInstance.post("/profile/update-password", {
      password,
    });
    return response;
  };

  return {
    generateOtp,
    verifyOtp,
    refresh,
    logIn,
    registerDoctor,
    registerPA,
    deletePA,
    addPatient,
    getPackages,
    registerAddPatient,
    getSession,
    startSession,
    addPatientQueue,
    getPatientQueue,
    sendNextPatient,
    getPatientQueueChecked,
    deletePatientFromQueue,
    endSession,
    registerPAByDoctor,
    replacePAByDoctor,
    getDoctorDashboard,
    getClinicDashboard,
    requestNextPatientDoctor,
    waivePatientFee,
    addSubscription,
    deleteSubscription,
    getUnPaidSubscription,
    getPaidSubscription,
    getStatistics,
    getGraphs,
    getPatients,
    getPaymentHistory,
    getPA,
    getCities,
    getNotifications,
    clinicEditFee,
    doctorsendNextPatient,
    updatePassword,
  };
};
