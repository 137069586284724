import { createContext, useContext, useEffect, useState } from "react";
import Snackbar from "../components/Snackbar";

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [shown, setShown] = useState(false);

  const hide = () => {
    setData(null);
    setShown(false);
  };

  useEffect(() => {
    if (data) {
      const timer = setTimeout(hide, 5000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [data]);

  const show = (type = "success", message = "Success") => {
    setShown(true);
    setData({ type, message });
  };

  return (
    <SnackbarContext.Provider value={{ show }}>
      {children}
      <Snackbar {...data} shown={shown} hide={hide} />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const snackbar = useContext(SnackbarContext);

  return snackbar;
};
