import React from "react";

const Toggle = ({
  value = false,
  onChange = () => {},
  onClick = () => {},
  disabled = false,
}) => {
  return (
    <div>
      <label className="relative inline-flex items-center cursor-pointer">
        <input
          type="checkbox"
          value={value}
          checked={!!value}
          onChange={onChange}
          onClick={onClick}
          className="sr-only peer"
          disabled={disabled}
        />
        <div className="w-[2.125rem] h-[0.875rem] bg-neutral-30 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[-0.1875rem] after:left-[-0.1875rem] after:bg-white peer-checked:after:bg-primary-70 after:shadow-[0px_0.1px_0.3px_rgba(0,0,0,0.1),0px_1px_2px_rgba(0,0,0,0.2)] after:rounded-full after:h-[1.25rem] after:w-[1.25rem] after:transition-all dark:border-primary-60 peer-checked:bg-primary-30"></div>
      </label>
    </div>
  );
};

export default Toggle;
