import { useEffect, useState } from "react";
import useAddToHomeScreen from "../../hooks/useAddToHomeScreen";
import Popup from "../Popup";
import DownloadIcon from "../../assets/img/download.svg";
import Button from "../Button";

const AddToHomeSreen = () => {
  const [prompt, promptToInstall] = useAddToHomeScreen();
  const [isVisible, setVisibleState] = useState(false);

  const hide = () => setVisibleState(false);

  useEffect(() => {
    if (prompt) {
      setVisibleState(true);
    }
  }, [prompt]);

  return (
    <Popup visible={isVisible} hide={hide} icon={DownloadIcon}>
      <h4 className="heading-4 font-bold">Install App</h4>
      <p className="body-small text-neutral-60">
        Do you want to add My Clinic to your home screen?
      </p>
      <div className="flex flex-row justify-row gap-[1rem] w-full">
        <Button
          text="Cancel"
          fullWidth
          size="btn-large"
          variant="btn-secondary"
          onClick={hide}
        />
        <Button
          text="Confirm"
          fullWidth
          size="btn-large"
          onClick={() => {
            promptToInstall();
            hide();
          }}
        />
      </div>
    </Popup>
  );
};

export default AddToHomeSreen;
