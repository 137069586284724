import { useState } from "react";

const useTabs = (tabs = [], idx) => {
  const [currentTab, setCurrentTab] = useState(tabs.at(idx));

  const setTab = (idx) => setCurrentTab(tabs.at(idx));

  const isCurrent = (idx) => currentTab.id === tabs.at(idx)?.id;

  return { tabs, currentTab, setTab, isCurrent };
};

export default useTabs;
