import React from "react";
import ScreenLayout from "../../components/ScreenLayout";

const NotificationItem = ({ read = false, text, date, time }) => {
  const datetimeClass = "body-small text-neutral-60";
  return (
    <button
      className={`p-[1.25rem] flex flex-col gap-[0.25rem] border-b-[1px] border-neutral-20 ${
        !read && "bg-primary-10"
      } text-left`}
    >
      <p className="body-large font-semibold text-neutral-90">{text}</p>
      <div className="flex flex-row gap-[1rem]">
        <p className={datetimeClass}>{date}</p>
        <p className={datetimeClass}>{time}</p>
      </div>
    </button>
  );
};

const Section = ({ title, children }) => {
  return (
    <div>
      <h3 className="body-small font-bold mb-[0.25rem]">{title}</h3>
      <div className="mx-[-1.25rem] flex flex-col border-t-[1px] border-neutral-20">
        {children}
      </div>
    </div>
  );
};

const newNotifications = [
  {
    text: "Dr. Haris Ali has Invited to Link Your Account With XYZ Clinic",
    date: "16 March 23",
    time: "05:06 PM",
    read: false,
  },
  {
    text: "Dr. Abbas Rizvi has waived 50% for Imtiaz Hussam",
    date: "16 March 23",
    time: "03:06 PM",
    read: true,
  },
];

const earlierNotifications = [
  {
    text: "Dr. Maha Ilyas has requested to send in a new patient",
    date: "15 March 23",
    time: "05:06 PM",
    read: true,
  },
  {
    text: "Dr Abbas Rizvi has Invited to Link Your Account With XYZ Clinic ",
    date: "15 March 23",
    time: "05:06 PM",
    read: true,
  },
];

const NotificationScreen = () => {
  return (
    <ScreenLayout title="Notifications">
      <Section title="New">
        {newNotifications.map((notification, idx) => (
          <NotificationItem key={idx} {...notification} />
        ))}
      </Section>
      <Section title="Earlier">
        {earlierNotifications.map((notification, idx) => (
          <NotificationItem key={idx} {...notification} />
        ))}
      </Section>
    </ScreenLayout>
  );
};

export default NotificationScreen;
