import React, { useEffect, useState } from "react";
import AuthLayout from "../../components/AuthLayout";
import Button from "../../components/Button";
import SelectInput from "../../components/SelectInput";
import TextInput from "../../components/TextInput";
import { useOnboarding } from "../../context/Onboarding.context";
import { useSelector } from "react-redux";
import { useApi } from "../../api";
import Popup from "../../components/Popup";
import Check from "../../components/Check";
import InputError from "../../components/InputError";
import moment from "moment";

const RegisterClinic = () => {
  const { registerClinic } = useOnboarding();
  const { states } = useSelector((state) => state.userReducer);

  const api = useApi();

  const [cities, setCities] = useState([]);
  const [citiesLoading, setCitiesLoading] = useState(false);

  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);

  const show = (e) => {
    e.stopPropagation();
    setShowTermsAndConditions(true);
  };
  const hide = () => {
    setShowTermsAndConditions(false);
  };

  useEffect(() => {
    setCitiesLoading(true);
    if (registerClinic.form.values.clinicState) {
      api
        .getCities(registerClinic.form.values.clinicState)
        .then((res) => {
          setCities(res.data.body.cities);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setCitiesLoading(false);
        });
    } else {
      setCities([]);
    }
    // eslint-disable-next-line
  }, [registerClinic.form.values.clinicState]);

  return (
    <AuthLayout
      title="Clinic Information"
      withoutLogo={true}
      bottomLink={{
        goBack: true,
      }}
    >
      <TextInput
        placeholder="Enter clinic name"
        label="Clinic Name"
        required
        name="clinicName"
        value={registerClinic.form.values.clinicName}
        error={registerClinic.form.errors.clinicName}
        setValue={registerClinic.form.handleChange("clinicName")}
        onBlur={registerClinic.form.handleBlur("clinicName")}
        onFocus={() => registerClinic.form.setFieldTouched("clinicName", true)}
        touched={registerClinic.form.touched.clinicName}
      />
      <TextInput
        placeholder="Enter clinic address"
        label="Address"
        required
        name="clinicAddress"
        value={registerClinic.form.values.clinicAddress}
        setValue={registerClinic.form.handleChange("clinicAddress")}
        onBlur={registerClinic.form.handleBlur("clinicAddress")}
        onFocus={() =>
          registerClinic.form.setFieldTouched("clinicAddress", true)
        }
        error={registerClinic.form.errors.clinicAddress}
        touched={registerClinic.form.touched.clinicAddress}
      />
      <div className="flex flex-row gap-[1rem]">
        <div className="flex-1">
          <SelectInput
            label="Province"
            required
            options={states}
            valueGetter={(value) => value}
            textGetter={(value) => value}
            name="clinicState"
            value={registerClinic.form.values.clinicState}
            setValue={(value) => {
              registerClinic.form.setFieldValue("clinicState", value);
              registerClinic.form.setFieldValue("clinicCity", null);
              registerClinic.form.validateField("clinicState");
            }}
            onBlur={registerClinic.form.handleBlur("clinicState")}
            onFocus={() =>
              registerClinic.form.setFieldTouched("clinicState", true)
            }
            // error={registerClinic.form.errors.clinicState}
            touched={registerClinic.form.touched.clinicState}
          />
        </div>
        <div className="flex-1">
          <SelectInput
            label="City"
            required
            options={cities}
            valueGetter={(value) => value}
            textGetter={(value) => value}
            name="clinicCity"
            value={registerClinic.form.values.clinicCity}
            setValue={(value) => {
              registerClinic.form.setFieldValue("clinicCity", value);
              registerClinic.form.validateField("clinicCity");
            }}
            onBlur={registerClinic.form.handleBlur("clinicCity")}
            onFocus={() =>
              registerClinic.form.setFieldTouched("clinicCity", true)
            }
            error={registerClinic.form.errors.clinicCity}
            touched={registerClinic.form.touched.clinicCity}
          />
        </div>
      </div>
      <div className="flex flex-row gap-[1rem]">
        <TextInput
          placeholder="Enter speciality"
          label="Speciality"
          required
          name="speciality"
          value={registerClinic.form.values.speciality}
          setValue={registerClinic.form.handleChange("speciality")}
          onBlur={registerClinic.form.handleBlur("speciality")}
          onFocus={() =>
            registerClinic.form.setFieldTouched("speciality", true)
          }
          error={registerClinic.form.errors.speciality}
          touched={registerClinic.form.touched.speciality}
        />
        <TextInput
          placeholder="Fees"
          label="Fees"
          required
          isNumeric
          name="feeAmount"
          value={registerClinic.form.values.feeAmount}
          setValue={registerClinic.form.handleChange("feeAmount")}
          onBlur={registerClinic.form.handleBlur("feeAmount")}
          onFocus={() => registerClinic.form.setFieldTouched("feeAmount", true)}
          error={registerClinic.form.errors.feeAmount}
          touched={registerClinic.form.touched.feeAmount}
        />
      </div>
      <div className="flex flex-row gap-[8px] items-center">
        <Check
          name="termsAccepted"
          value={registerClinic.form.values.termsAccepted}
          error={registerClinic.form.errors.termsAccepted}
          setValue={(value) => {
            registerClinic.form.setFieldValue("termsAccepted", value);
          }}
          onFocus={() =>
            registerClinic.form.setFieldTouched("termsAccepted", true)
          }
          onBlur={registerClinic.form.handleBlur("termsAccepted")}
          touched={registerClinic.form.touched.termsAccepted}
        />
        <p
          className="body-small font-medium text-neutral-40"
          onClick={() => {
            registerClinic.form.setFieldValue(
              "termsAccepted",
              !registerClinic.form.values.termsAccepted
            );
          }}
        >
          I have read and accepted{" "}
          <button onClick={show} className="text-primary-70 font-semibold">
            Terms and Conditions
          </button>
        </p>
      </div>
      {registerClinic.form.errors.termsAccepted &&
        registerClinic.form.touched.termsAccepted && (
          <InputError error={registerClinic.form.errors.termsAccepted} />
        )}
      <div className="mt-[2rem] flex flex-col gap-[0.5rem]">
        <Button
          text="Continue"
          onClick={registerClinic.form.handleSubmit}
          disabled={
            registerClinic.loading ||
            citiesLoading ||
            !registerClinic.form.isValid
          }
        />
      </div>
      <Popup visible={showTermsAndConditions} hide={hide}>
        <h4 className="heading-4 font-bold">Terms and Conditions</h4>
        <p className="body-small font-bold">
          Effective Date & Time: {moment(new Date()).format("LLL")}
        </p>
        <p className="body-small">
          This My Clinic App - Doctor Terms of Use ("Agreement") is entered into
          between [My Clinic App] ("QP," "Quantum Paradigm (Private) Limited,"
          or "First Party" or “our” or “us” or “we”) and you ("Doctor Name" or
          "you" or “your”) upon your sign-up and use of the My Clinic App (the
          "App"). This Agreement governs your access to and use of the App as a
          registered doctor. By signing up and using the App, you acknowledge
          that you have read, understood, and agree to be bound by the terms and
          conditions of this Agreement.
        </p>
        <p className="body-small font-bold">1. App Access and Use</p>
        <p className="body-small ">
          <span className="font-bold">(a) Eligibility:</span> By signing up and
          using the App, you represent and warrant that you are a licensed
          medical professional eligible to practice medicine within the
          locality/jurisdiction in which you are providing services through the
          App.
        </p>
        <p className="body-small ">
          <span className="font-bold">(b) License Grant:</span> Subject to your
          compliance with the terms of this Agreement, we grant you a limited,
          non-exclusive, non-transferable, and revocable license to access and
          use the App solely for the purpose of providing a means to streamline
          your medical practice through the App.
        </p>
        <p className="body-small ">
          <span className="font-bold">(c) User Account:</span> You will be
          required to create a user account to access the App. You agree to
          provide accurate, complete, and up-to-date information during the
          registration process and to keep your account credentials
          confidential. You are solely responsible for all activities that occur
          under your account.
        </p>
        <p className="body-small ">
          <span className="font-bold">(d) Sub User Account:</span> You may, also
          for the ease of your clinic management, create a sub-user for your
          personal assistant.
        </p>
        <p className="body-small ">
          <span className="font-bold">
            (e) Patient Registration & Sequence Management:
          </span>{" "}
          Through the My Clinic App, you may register your patients, manage
          their sequence and also monitor your clinic cash collection. You agree
          to provide services in accordance with applicable laws, regulations,
          and professional standards. You acknowledge that you are solely
          responsible for all diagnoses, treatments, and interactions with
          patients, and we shall not be liable for any & all acts or omissions
          by you.
        </p>
        <p className="body-small ">
          <span className="font-bold">(f) Compliance with Laws:</span>You agree
          to comply with all applicable laws, rules, and regulations governing
          the provision of medical services, patient privacy, and data
          protection, including but not limited to any local laws and
          regulations.
        </p>
        <p className="body-small ">
          <span className="font-bold">(g) Prohibited Activities:</span> You
          shall not use the App for any unlawful, fraudulent, or malicious
          purposes, including but not limited to unauthorized access, data
          breaches, or dissemination of patient information. You shall not
          engage in any activity that may harm our reputation or disrupt the
          operation of the App. In breaching this clause you indemnify us and
          you claim sole responsibility of any and all consequences of the same.
        </p>
        <p className="body-small font-bold">2. Intellectual Property</p>
        <p className="body-small ">
          <span className="font-bold">(a) Ownership:</span> The App and all
          intellectual property rights associated with it are and shall remain
          our exclusive property. This Agreement does not grant you any
          ownership rights or licenses in the App, except as expressly provided
          herein.
        </p>
        <p className="body-small ">
          <span className="font-bold">(b) Feedback:</span> If you provide any
          suggestions, ideas, or feedback regarding the App ("Feedback"), you
          hereby grant the Clinic a non-exclusive, royalty-free, perpetual,
          irrevocable, and worldwide license to use, reproduce, modify,
          distribute, and exploit such Feedback for any purpose without any
          obligation to you.
        </p>
        <p className="body-small font-bold">3. Data Privacy and Security</p>
        <p className="body-small ">
          <span className="font-bold">(a) Data Collection and Use:</span> By
          using the My Clinic App, you acknowledge and agree that we may
          collect, store, process, and use your personal information and patient
          data in accordance with our Privacy Policy. You are responsible for
          obtaining patient consent and complying with applicable privacy laws
          and regulations regarding the collection and use of patient data.
        </p>
        <p className="body-small ">
          <span className="font-bold">(b) Security:</span> We employ reasonable
          security measures to protect your personal information and patient
          data. However, you acknowledge that no data transmission over the
          internet or storage system can be guaranteed to be 100% secure, and we
          shall not be liable for any unauthorized access, loss, or alteration
          of data.
        </p>
        <p className="body-small font-bold">4. Termination</p>
        <p className="body-small ">
          <span className="font-bold">(a) Termination by User:</span>Should the
          user opts to discontinue using the App, may do so by not using the App
          for the subscription period or deactivating their account. Please also
          note that there is a no refund policy. Furthermore you may opt to not
          renew your subscription once the live one expires.
        </p>
        <p className="body-small ">
          <span className="font-bold">(b) Termination by Us:</span>Should there
          be any misrepresentation whatsoever by you with regards to the
          information provided by you or with regards to our product(s) /
          service(s), misuse of our product(s) / service(s) by you, any
          fraudulent activity performed by you with respect to our product(s) /
          service(s), etc. Your account will first be temporarily suspended,
          giving you a chance to provide reasonable evidence of the contrary.
          Failure in doing so will abort your access to the account and the
          account will no longer be yours. In such a case you indemnify us from
          any liability on account of your actions / activities.
        </p>
        <p className="body-small font-bold">
          5. Clinical & Bio Data Collection
        </p>
        <p className="body-small ">
          <span className="font-bold">(a) Patient Data Collection:</span> The
          App may collect and store clinical and bio data of patients ("Patient
          Data") as part of the medical consultation process. Patient Data may
          include but is not limited to medical history, diagnostic reports,
          treatment plans, and vital signs. You acknowledge and agree that the
          collection and use of Patient Data shall be in compliance with
          applicable privacy laws and regulations and the Clinic's Privacy
          Policy.
        </p>
        <p className="body-small ">
          <span className="font-bold">(b) Consent and Confidentiality:</span>{" "}
          You are responsible for obtaining patient consent for the collection,
          use, and disclosure of their Patient Data through the My Clinic App.
          You shall treat all Patient Data as confidential and shall not
          disclose it to any third party without proper authorization, unless
          required by law or as necessary to provide medical services to the
          patient.
        </p>
        <p className="body-small ">
          <span className="font-bold">(c) Data Accuracy and Integrity:</span>{" "}
          You agree to ensure the accuracy, completeness, and integrity of the
          Patient Data you enter or access through the App. You shall promptly
          update any inaccuracies or discrepancies in the Patient Data and
          notify us of any potential data breaches or security incidents.
        </p>
        <p className="body-small font-bold">6. Ownership Rights</p>
        <p className="body-small ">
          <span className="font-bold">(a) App Ownership:</span> The App,
          including its software, design, features, and functionality, is owned
          by Quantum Paradigm (Private) Limited and is protected by copyright,
          trademark, and other intellectual property laws. You acknowledge that
          we retain all rights, title, and interest in and to the App, including
          any updates, enhancements, or modifications thereto.
        </p>
        <p className="body-small ">
          <span className="font-bold">(b) License Restrictions:</span> Except as
          expressly permitted under this Agreement, you shall not copy, modify,
          distribute, reverse engineer, decompile, or create derivative works of
          the App. You shall not remove, alter, or obscure any copyright,
          trademark, or proprietary notices displayed within the App.
        </p>
        <p className="body-small ">
          <span className="font-bold">(c) Feedback Ownership:</span> Any
          feedback, suggestions, or ideas provided by you regarding the App
          shall be considered our exclusive property. We may use, implement, or
          modify such feedback without any obligation to you, and you hereby
          waive any rights or claims in connection with such feedback.
        </p>
        <p className="body-small font-bold">7. Indemnification</p>
        <p className="body-small">
          You agree to indemnify, defend, and hold harmless Quantum Paradigm
          (Private) Limited, its affiliates, officers, directors, employees, and
          agents from any claims, liabilities, damages, losses, or expenses
          (including reasonable attorneys' fees) arising out of or in connection
          with your use of the App, your provision of medical services, or any
          violation of this Agreement or applicable laws.
        </p>
        <p className="body-small font-bold">
          8. Governing Law and Dispute Resolution
        </p>
        <p className="body-small">
          This Agreement shall be governed by and construed in accordance with
          the laws of Pakistan.
        </p>
        <p className="body-small font-bold">9. Amendments</p>
        <p className="body-small">
          The Clinic reserves the right to modify or update this Agreement at
          any time by providing notice through the App. Your continued use of
          the App after such modifications shall constitute your acceptance of
          the updated Agreement.
        </p>
        <Button text="Okay" fullWidth size="btn-large" onClick={hide} />
      </Popup>
    </AuthLayout>
  );
};

export default RegisterClinic;
