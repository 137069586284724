import { useEffect, useState } from "react";
import { useApi } from "../api";
import { useSnackbar } from "../context/Snackbar.context";
import getErrorMessage from "../utils/getErrorMessage";
import { useFormik } from "formik";
import * as Yup from "yup";
import fcmTopicEnum from "../utils/fcmTopicEnum";
import { useNotifications } from "../context/Notification.context";
import { useSelector } from "react-redux";

const useDoctorClinic = (mode = "current") => {
  const api = useApi();
  const snackbar = useSnackbar();

  const notifications = useNotifications();
  const { clinicDetails } = useSelector((state) => state.userReducer);

  const feeAmount = clinicDetails[0]?.clinicFees;

  const [sessionLoading, setSessionLoading] = useState(false);
  const [sessionDetails, setSessionDetails] = useState(null);
  const [sessionError, setSessionError] = useState(null);

  const [queueLoading, setQueueLoading] = useState(false);
  const [queueDetails, setQueueDetails] = useState([]);
  const [queueError, setQueueError] = useState(null);

  const [historyQueueLoading, setHistoryQueueLoading] = useState(false);
  const [historyQueueDetails, setHistoryQueueDetails] = useState([]);
  const [historyQueueError, setHistoryQueueError] = useState(null);

  const [waiveLoading, setWaiveLoading] = useState(false);
  const [requestNextLoading, setRequestNextLoading] = useState(false);
  const [sendNextLoading, setSendNextLoading] = useState(false);

  const startSession = () => {
    api
      .startSession()
      .then((res) => {
        getSession();
        snackbar.show("success", "Session started successfully");
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        snackbar.show("error", message);
      });
  };

  const endSession = () => {
    api
      .endSession()
      .then((res) => {
        getSession();
        snackbar.show("success", "Session has ended");
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        snackbar.show("error", message);
      });
  };

  const reset = () => {
    setSessionLoading(false);
    setSessionDetails(null);
    setSessionError(null);
    setQueueLoading(false);
    setQueueDetails([]);
    setQueueError(null);
  };

  const getSession = () => {
    reset();
    setSessionLoading(true);
    setSessionError(null);
    api
      .getSession()
      .then((res) => {
        setSessionDetails(res.data.body);
      })
      .catch((error) => {
        console.log(error);
        const message = getErrorMessage(error);
        snackbar.show("error", message);
        setSessionDetails(null);
        setSessionError(message);
      })
      .finally(() => {
        setSessionLoading(false);
      });
  };

  const getQueue = () => {
    setQueueLoading(true);
    setHistoryQueueLoading(true);
    setQueueError(null);
    setHistoryQueueError(null);
    if (sessionDetails?.isActive) {
      api
        .getPatientQueue()
        .then((res) => {
          const data = res.data.body.patientQueue;
          setQueueDetails(data);
        })
        .catch((error) => {
          console.log(error);
          setQueueDetails([]);
          snackbar.show("error", "Error fetching queue");
          setQueueError("Error fetching queue");
        })
        .finally(() => {
          setQueueLoading(false);
        });

      api
        .getPatientQueueChecked()
        .then((res) => {
          const data = res.data.body.patientQueue;
          setHistoryQueueDetails(data);
        })
        .catch((error) => {
          console.log(error);
          setHistoryQueueDetails([]);
          snackbar.show("error", "Error fetching history");
          setHistoryQueueError("Error fetching history");
        })
        .finally(() => {
          setHistoryQueueLoading(false);
        });
    }
  };

  useEffect(() => {
    getSession();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (mode) {
      getQueue();
    }
    // eslint-disable-next-line
  }, [mode, sessionDetails]);

  useEffect(
    () => {
      const notif = notifications.notificationsWithoutPopup.at(
        notifications.notificationsWithoutPopup.length - 1
      );
      const popupNotif = notifications.notifications.at(
        notifications.notificationsWithoutPopup.length - 1
      );
      if (notif) {
        if (
          notif?.data?.msg === fcmTopicEnum.START_SESSION ||
          notif?.data?.msg === fcmTopicEnum.ADD_PATIENT_QUEUE ||
          notif?.data?.msg === fcmTopicEnum.DELETE_PATIENT_QUEUE
        ) {
          getSession();
        }
      }

      if (popupNotif) {
        if (popupNotif?.data?.msg === fcmTopicEnum.SEND_NEXT_PATIENT) {
          getSession();
        }
      }
    },
    // eslint-disable-next-line
    [
      notifications.notificationsWithoutPopup.length,
      notifications.notifications.length,
    ]
  );

  let currentPatient;
  let uncheckedPatients = queueDetails;

  if (queueDetails?.length) {
    const firstPatient = queueDetails.at(0);
    if (firstPatient.isDoctorChecked === false) {
      [currentPatient, ...uncheckedPatients] = queueDetails;
    }
  }

  const waiveFee = (waiveAmount) => {
    if (currentPatient) {
      setWaiveLoading(true);
      api
        .waivePatientFee({ patientID: currentPatient.PatientID, waiveAmount })
        .then((res) => {
          getSession();
        })
        .catch((error) => {})
        .finally(() => {
          setWaiveLoading(false);
        });
    }
  };

  const requestNextPatient = () => {
    setRequestNextLoading(true);
    if (currentPatient) {
      const { PatientID: patientID } = currentPatient;
      api
        .requestNextPatientDoctor({ patientID })
        .then(() => {
          snackbar.show("success", "Requested next patient");
          getSession();
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          snackbar.show("error", message);
        })
        .finally(() => {
          setRequestNextLoading(false);
        });
    }
  };

  const sendNextPatient = (newPatientID, successCallback = () => {}) => {
    api
      .doctorsendNextPatient({ newPatientID })
      .then(() => {
        snackbar.show("success", "Patient sent successfully");
        successCallback();
        getSession();
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        snackbar.show("error", message);
      })
      .finally(() => {
        setSendNextLoading(false);
      });
  };

  const waiveForm = useFormik({
    initialValues: {
      waiveAmount: "",
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      waiveAmount: Yup.number()
        .required("Waive amount is required")
        .max(feeAmount, "Cannot be greater than actual fee"),
    }),
    onSubmit: (values, { resetForm }) => {
      waiveFee(values.waiveAmount);
      resetForm();
    },
  });

  const getWaiveAmountByPercentage = (percentage) => {
    return (feeAmount * percentage) / 100;
  };

  const setWaiveAmountByPercentage = (percentage) => {
    waiveForm.setFieldValue(
      "waiveAmount",
      getWaiveAmountByPercentage(percentage)
    );
  };

  const value = {
    session: {
      loading: sessionLoading,
      data: sessionDetails,
      error: sessionError,
      start: startSession,
      end: endSession,
      fetch: getSession,
    },
    queue: {
      loading: queueLoading,
      currentPatient,
      waiveForm,
      waiveLoading,
      data: uncheckedPatients,
      error: queueError,
      nextPatient: {
        request: requestNextPatient,
        loading: requestNextLoading,
      },
      sendNext: {
        send: sendNextPatient,
        loading: sendNextLoading,
      },
    },
    history: {
      loading: historyQueueLoading,
      data: historyQueueDetails,
      error: historyQueueError,
    },
    getWaiveAmountByPercentage,
    setWaiveAmountByPercentage,
  };

  return value;
};

export default useDoctorClinic;
