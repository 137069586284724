import React from "react";
import { useNavigate } from "react-router";
import ArrowLeftLightIcon from "../../assets/img/arrow-left-light.svg";

const ScreenHeader = ({ title, back }) => {
  const navigate = useNavigate();

  let clickHandler = () => {
    navigate(-1);
  };

  if (back) {
    clickHandler = () => {
      navigate(back.path, { state: back.state, replace: true });
    };
  }

  return (
    <div className="flex flex-row items-center bg-primary-70 p-[1.5rem]">
      <button onClick={clickHandler}>
        <img src={ArrowLeftLightIcon} alt="go-back" />
      </button>
      <h3 className="justify-self-center heading-4 font-semibold text-white flex-1 text-center">
        {title}
      </h3>
    </div>
  );
};

export default ScreenHeader;
