import React, { useState } from "react";
import CaretDownIcon from "../../assets/img/caret-down.svg";

const ChipSelect = ({ options = [], value, setValue }) => {
  const [shown, setShown] = useState(false);

  const toggle = () => setShown((shown) => !shown);

  const hide = () => setShown(false);

  const handleClick = (option) => {
    setValue(option.value);
    hide();
  };

  const selected = options.find((option) => option.value === value);

  return (
    <div className="relative">
      <button
        className="bg-primary-0 rounded-full p-[0.375rem_1rem] flex flex-row gap-[0.5rem] items-center"
        onClick={toggle}
      >
        <div className="text-primary-70 body-micro font-semibold">
          {selected?.text}
        </div>
        <img src={CaretDownIcon} alt="toggle" />
      </button>
      {shown && (
        <div className="absolute flex flex-col items-start right-0 rounded-[0.25rem] border-[1px] border-primary-20 bg-primary-0">
          {options.map((option, idx) => {
            const currentActive = option.value === value;
            return (
              <button
                onClick={() => handleClick(option)}
                key={idx}
                className={`body-micro p-[0.5rem] min-w-[5.25rem] ${
                  currentActive
                    ? "text-neutral-90 font-semibold"
                    : "text-neutral-60 font-normal"
                }`}
                disabled={currentActive}
              >
                {option.text}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ChipSelect;
