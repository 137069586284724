import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { useApi } from "../api";
import { useSnackbar } from "../context/Snackbar.context";
import getErrorMessage from "../utils/getErrorMessage";
import { useDispatch } from "react-redux";
import { updateClinicFee } from "../redux/actions";

const useEditFee = ({ successCallback = () => {} }) => {
  const api = useApi();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const form = useFormik({
    initialValues: {
      feeAmount: "",
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      feeAmount: Yup.number()
        .required("Fee amount is required")
        .min(100, "Fee must comprise minimum 3 characters"),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      api
        .clinicEditFee({ feeAmount: values.feeAmount })
        .then((res) => {
          snackbar.show("success", "Fee updated");
          dispatch(updateClinicFee(values.feeAmount));
          successCallback();
          resetForm();
        })
        .catch((err) => {
          const message = getErrorMessage(err);
          snackbar.show("error", message);
          setError(message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return {
    loading,
    error,
    form,
  };
};

export default useEditFee;
