import React from "react";

const TabView = ({ tabs }) => {
  return (
    <div className="text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 mx-[-1.25rem]">
      <ul className="flex -mb-px">
        {tabs.tabs.map((tab, idx) => {
          const isCurrent = tabs.isCurrent(idx);

          return (
            <li className="flex-1 w-0" key={idx}>
              <button
                className={`body-large w-full truncate ${
                  isCurrent
                    ? "text-primary-70 border-primary-70"
                    : "text-neutral-60 border-neutral-10"
                } font-semibold p-[0.5rem] border-b-[1px]`}
                onClick={() => tabs.setTab(idx)}
              >
                {tab.label} {tab.count !== undefined ? `(${tab.count})` : ""}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TabView;
