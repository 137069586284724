import React, { Fragment } from "react";
import Button from "../../components/Button";
import PhoneInput from "../../components/PhoneInput";
import ScreenLayout from "../../components/ScreenLayout";
import Spinner from "../../components/Spinner";
import SelectInput from "../../components/SelectInput";
import TextInput from "../../components/TextInput";
import useCreatePatient from "../../hooks/useCreatePatient";
import { genders } from "../../utils/constants";
import { useLocation, useParams } from "react-router-dom";

const CreatePatient = () => {
  const createPatient = useCreatePatient();
  const { clinicID } = useParams();
  const location = useLocation();

  return (
    <ScreenLayout
      title="Create Patient"
      back={
        clinicID
          ? {
              path: "/pa",
              state: { clinicID },
            }
          : undefined
      }
    >
      <div className="flex flex-col gap-[1.5rem]">
        <div className="flex flex-row justify-between">
          <h3 className="heading-3">
            Create Patient{" "}
            {location.state?.doctorName && `(${location.state?.doctorName})`}
          </h3>
        </div>
        <PhoneInput
          label="Cell Number"
          required
          name="cellNumber"
          value={createPatient.form.values.cellNumber}
          error={createPatient.form.errors.cellNumber}
          setValue={createPatient.form.handleChange("cellNumber")}
          onBlur={createPatient.form.handleBlur("cellNumber")}
          onFocus={() => createPatient.form.setFieldTouched("cellNumber", true)}
          touched={createPatient.form.touched.cellNumber}
        />
        {createPatient.getPatients.loading ? (
          <Spinner />
        ) : createPatient.getPatients.patients?.length ? (
          <div className="border-[1px] rounded-[0.25rem] border-neutral-20 p-[0.625rem] flex flex-col gap-[0.625rem]">
            <Button
              text="Add New Patient"
              fullWidth
              size="btn-large"
              variant="btn-tertiary"
              onClick={createPatient.getPatients.reset}
            />
            {createPatient.getPatients.patients?.map((patient, idx) => (
              <Fragment key={idx}>
                <hr className="border-b-[1px] border-neutral-20" />
                <div className="flex flex-row justify-between items-center">
                  <p className="body-large">{patient.patientName}</p>
                  <Button
                    text="Select"
                    size="btn-medium"
                    variant="btn-tertiary"
                    onClick={() =>
                      createPatient.addPatientQueue({
                        patientID: patient.patientID,
                      })
                    }
                    disabled={createPatient.loading}
                  />
                </div>
              </Fragment>
            ))}
          </div>
        ) : (
          <>
            <TextInput
              placeholder="Enter name"
              label="Name"
              required
              name="patientName"
              value={createPatient.form.values.patientName}
              error={createPatient.form.errors.patientName}
              setValue={createPatient.form.handleChange("patientName")}
              onBlur={createPatient.form.handleBlur("patientName")}
              onFocus={() =>
                createPatient.form.setFieldTouched("patientName", true)
              }
              touched={createPatient.form.touched.patientName}
            />
            <div className="flex flex-row justify-between gap-[0.75rem]">
              <SelectInput
                placeholder="--Select gender--"
                {...genders}
                name="gender"
                value={createPatient.form.values.gender}
                error={createPatient.form.errors.gender}
                setValue={createPatient.form.handleChange("gender")}
                onBlur={createPatient.form.handleBlur("gender")}
                onFocus={() =>
                  createPatient.form.setFieldTouched("gender", true)
                }
                touched={createPatient.form.touched.gender}
                label="Gender"
              />
              <TextInput
                placeholder="Enter age"
                label="Age"
                isNumeric
                required
                name="age"
                value={createPatient.form.values.age}
                error={createPatient.form.errors.age}
                setValue={createPatient.form.handleChange("age")}
                onBlur={createPatient.form.handleBlur("age")}
                onFocus={() => createPatient.form.setFieldTouched("age", true)}
                touched={createPatient.form.touched.age}
              />
            </div>
            <div className="mt-[2rem] flex flex-col gap-[0.5rem]">
              <Button
                text="Add Patient"
                fullWidth
                size="btn-xlarge"
                disabled={createPatient.loading || !createPatient.form.isValid}
                onClick={createPatient.form.handleSubmit}
              />
            </div>
          </>
        )}
      </div>
    </ScreenLayout>
  );
};

export default CreatePatient;
