import React from "react";

const InputContainer = ({
  children,
  small = false,
  focused = false,
  error,
  success = false,
  padding = null,
}) => {
  const classes = ["input-container", "relative"];

  if (small) {
    classes.push("input-container-small");
  } else {
    // classes.push("input-container-medium");
  }

  if (error) {
    classes.push("input-container-error");
  } else if (success) {
    classes.push("input-container-success");
  } else if (focused) {
    classes.push("input-container-active");
  }

  if (padding) {
    classes.push(`p-[${padding}]`);
  }

  return <div className={classes.join(" ")}>{children}</div>;
};

export default InputContainer;
