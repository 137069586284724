import React, { useRef, useState } from "react";
import PKFlag from "../../assets/img/pk-flag.svg";
import InputContainer from "../InputContainer";
import InputError from "../InputError";
import InputLabel from "../InputLabel";

const PhoneInput = ({
  label,
  value,
  setValue,
  required = false,
  error,
  touched,
  onFocus,
}) => {
  const [focused, setFocused] = useState(false);

  const handleChange = (e) => {
    const newValue = e.target.value.replaceAll(" ", "");
    if (/^\d{0,10}$/.test(newValue)) {
      setValue(newValue);
    }
  };

  const inputRef = useRef();

  return (
    <div className="w-full">
      <InputLabel
        error={error && touched && value}
        label={label}
        focused={focused}
        required={required}
      />
      <InputContainer
        focused={focused}
        small={true}
        error={error && touched && value}
        success={value && !error && touched}
      >
        <div className="flex flex-row gap-[0.5rem] min-w-fit items-center border-r-[1px] border-neutral-40 pr-[0.5rem]">
          <img
            src={PKFlag}
            alt="PK"
            className="border-[1px] border-neutral-10"
          />
          <div className="body-large">+92</div>
          {/* <img src={ChevronDown} alt="dropdown" className="w-[0.75rem]" /> */}
        </div>
        <input
          ref={inputRef}
          onFocus={() => {
            setFocused(true);
            onFocus();
          }}
          onBlur={() => setFocused(false)}
          className="input-area"
          placeholder="3XX XXX XXXX"
          value={`${value.substring(0, 3)} ${value.substring(
            3,
            6
          )} ${value.substring(6, 10)}`.trim()}
          onChange={handleChange}
        />
      </InputContainer>
      {error && touched && value && <InputError error={error} />}
    </div>
  );
};

export default PhoneInput;
