import React, { useState } from "react";
import VisibilityOff from "../../assets/img/visibility-off.svg";
import Visibility from "../../assets/img/visibility.svg";
import InputContainer from "../InputContainer";
import InputError from "../InputError";
import HelperText from "../HelperText";
import InputLabel from "../InputLabel";
import { OnlyAlphabetsAndSpacesRegex } from "../../utils/regex";

const TextInput = ({
  name,
  placeholder,
  isHidden = false,
  value,
  setValue,
  appendStart,
  label,
  required = false,
  error,
  disabled = false,
  isNumeric = false,
  isAlphabetic = false,
  helperText,
  maxChars,
  touched,
  onFocus = () => {},
}) => {
  const [focused, setFocused] = useState(false);
  const [show, setShow] = useState(false);

  const handleChange = (e) => {
    let value = e.target.value;
    if (isNumeric) {
      if (value === "" || /^\d+$/.test(value)) {
        if (maxChars === undefined || value.length <= maxChars) {
          setValue(value);
        }
      }
    } else if (isAlphabetic) {
      if (value === "" || OnlyAlphabetsAndSpacesRegex.test(value)) {
        if (maxChars === undefined || value.length <= maxChars) {
          setValue(value);
        }
      }
    } else {
      setValue(value);
    }
  };
  return (
    <div className="w-full flex flex-col gap-[0.25rem]">
      <div className="flex flex-row justify-between items-center">
        <InputLabel
          error={error && touched && value}
          label={label}
          focused={focused}
          required={required}
        />
        {maxChars !== undefined && (
          <p className="body-micro text-neutral-60 font-normal">
            {value.length}/{maxChars}
          </p>
        )}
      </div>
      <InputContainer
        focused={focused}
        small={true}
        error={error && touched && value}
        success={value && !error && touched}
      >
        {appendStart && (
          <div className="text-neutral-90 font-semibold">{appendStart}</div>
        )}
        <input
          name={name}
          type={isHidden && !show ? "password" : "text"}
          disabled={disabled}
          onFocus={() => {
            setFocused(true);
            onFocus();
          }}
          onBlur={() => setFocused(false)}
          className="input-area"
          placeholder={placeholder}
          value={value}
          pattern={isNumeric ? `d*` : undefined}
          onChange={handleChange}
        />
        {isHidden && (
          <button onClick={() => setShow(!show)}>
            <img src={show ? VisibilityOff : Visibility} alt="show" />
          </button>
        )}
      </InputContainer>
      {!!helperText && <HelperText text={helperText} />}
      {error && touched && value && <InputError error={error} />}
    </div>
  );
};

export default TextInput;
