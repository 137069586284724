import { useEffect, useState } from "react";
import { useApi } from "../api";
import { useNotifications } from "../context/Notification.context";
import fcmTopicEnum from "../utils/fcmTopicEnum";

const useDoctorDashboard = () => {
  const api = useApi();
  const notifications = useNotifications();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const fetch = () => {
    setLoading(true);
    api
      .getDoctorDashboard()
      .then((res) => {
        setData(res.data.body);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(
    () => {
      const notif = notifications.notificationsWithoutPopup.at(
        notifications.notificationsWithoutPopup.length - 1
      );
      const popupNotif = notifications.notifications.at(
        notifications.notificationsWithoutPopup.length - 1
      );
      if (notif) {
        if (
          notif?.data?.msg === fcmTopicEnum.ADD_PATIENT_QUEUE ||
          notif?.data?.msg === fcmTopicEnum.DELETE_PATIENT_QUEUE
        ) {
          fetch();
        }
      }

      if (popupNotif) {
        if (
          popupNotif?.data?.msg === fcmTopicEnum.SEND_NEXT_PATIENT ||
          popupNotif?.data?.msg === fcmTopicEnum.START_SESSION
        ) {
          fetch();
        }
      }
    },
    // eslint-disable-next-line
    [
      notifications.notificationsWithoutPopup.length,
      notifications.notifications.length,
    ]
  );

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, []);

  return {
    loading,
    data,
    error: !loading && !data,
  };
};

export default useDoctorDashboard;
