import React from "react";
import ScreenLayout from "../../components/ScreenLayout";
import usePaymentHistory from "../../hooks/usePaymentHistory";
import { useParams } from "react-router-dom";
import Spinner from "../../components/Spinner";
import moment from "moment/moment";

const PaymentListItem = ({ date, name, emrNumber, amount, gender, age }) => {
  return (
    <div className="flex flex-col gap-[0.75rem]">
      <div className="flex flex-row justify-between gap-[0.75rem]">
        <div className="flex flex-col gap-[0.25rem]">
          <p className="body-small text-neutral-90">{date}</p>
          <h3 className="heading-3 font-bold">{name}</h3>
          <p className="body-small text-neutral-60">EMR# {emrNumber}</p>
        </div>
        <div className="flex flex-col gap-[0.25rem] items-end">
          <p className="body-small text-neutral-60">{moment(date).fromNow()}</p>
          <h3 className="heading-3 font-bold">
            Rs. {new Intl.NumberFormat("en-US").format(amount)}
          </h3>
          <p className="body-small text-neutral-60">
            {age} / {gender}
          </p>
        </div>
      </div>
      <hr className="border-neutral-20" />
    </div>
  );
};

const PaymentHistory = () => {
  const { cellNumber } = useParams();
  const paymentHistory = usePaymentHistory(cellNumber);

  const displayCellNumber = `${cellNumber.slice(0, 3)} ${cellNumber.slice(
    3,
    6
  )} ${cellNumber.slice(6, 9)} ${cellNumber.slice(9, 13)}`;

  return (
    <ScreenLayout title="Payment History">
      <h3 className="heading-3 font-bold">{displayCellNumber}</h3>
      {paymentHistory.loading ? (
        <Spinner />
      ) : (
        <div className="mt-[1.5rem] flex flex-col gap-[0.75rem]">
          {paymentHistory.payments?.map((payment, idx) => {
            return (
              <PaymentListItem
                key={idx}
                date={new Date(payment.createdTimestamp).toLocaleDateString()}
                name={payment.PatientName}
                emrNumber={payment.PatientID}
                amount={payment.LedgerAmount}
                gender={payment.Gender}
                age={moment(payment.DateOfBirth).fromNow(true)}
              />
            );
          })}
        </div>
      )}
    </ScreenLayout>
  );
};

export default PaymentHistory;
