import { useEffect, useState } from "react";
import { useApi } from "../api";
import getErrorMessage from "../utils/getErrorMessage";

const usePaymentHistory = (cellNumber) => {
  const api = useApi();

  const [payments, setPayments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetch = () => {
    if (!!cellNumber) {
      setLoading(true);
      setError(null);
      api
        .getPaymentHistory({
          cellNumber,
        })
        .then((res) => {
          const payments = res.data.body.result[0];
          setPayments(payments);
        })
        .catch((error) => {
          setError(getErrorMessage(error));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const reset = () => {
    setPayments(null);
  };

  useEffect(() => {
    reset();
    if (cellNumber) {
      fetch();
    }
    // eslint-disable-next-line
  }, [cellNumber]);

  return {
    payments,
    loading,
    error,
    cellNumber,
    fetch,
    reset,
  };
};

export default usePaymentHistory;
