import { PhoneRegex } from "../utils/regex";

const useValidator =
  ({ required = false, isPhone = false, isNumeric = false }) =>
  (value) => {
    if (required) {
      if (!value) {
        return "This is a required field";
      }
    }
    if (isPhone) {
      if (!PhoneRegex.test(value)) {
        return "Invalid phone number";
      }
    }
    if (isNumeric) {
      if (!/^\d+$/.test(value)) {
        return "Invalid value";
      }
    }
    return null;
  };

export default useValidator;
