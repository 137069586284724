import {
  SET_ACCESS_TOKEN,
  SET_REFRESH_TOKEN,
  SET_ROLE,
  SET_OTP_TOKEN,
  REGISTER_OTP_TOKEN,
  SET_CLINIC,
  VERIFY_OTP_TOKEN,
  SET_ONBOARDING_ROLE,
  SET_ONBOARDING_CELL,
  SET_USER_NAME,
  SET_LOGGED_IN,
  SET_OTP,
  RESET,
  SET_PA_DETAILS,
  SET_FCM_TOKEN,
  SET_STATES,
  UPDATE_CLINIC_ISFCM,
  UPDATE_CLINIC_FEE,
} from "./actions";

const initialState = {
  accessToken: "",
  refreshToken: "",
  role: "",
  userName: "",
  otp: null,
  otpToken: "",
  VotpToken: "",
  regOtpToken: "",
  clinicDetails: [],
  paDetails: null,
  onBoardingRole: "",
  onBoardingCell: null,
  loggedIn: false,
  fcmToken: null,
};

function userReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ACCESS_TOKEN:
      return { ...state, accessToken: action.payload };
    case SET_REFRESH_TOKEN:
      return { ...state, refreshToken: action.payload };
    case SET_ROLE:
      return { ...state, role: action.payload };
    case SET_ONBOARDING_ROLE:
      return { ...state, onBoardingRole: action.payload };
    case SET_ONBOARDING_CELL:
      return { ...state, onBoardingCell: action.payload };
    case SET_OTP:
      return { ...state, otp: action.payload };
    case SET_OTP_TOKEN:
      return { ...state, otpToken: action.payload };
    case VERIFY_OTP_TOKEN:
      return { ...state, VotpToken: action.payload };
    case REGISTER_OTP_TOKEN:
      return { ...state, regOtpToken: action.payload };
    case SET_CLINIC:
      return { ...state, clinicDetails: action.payload };
    case SET_LOGGED_IN:
      return { ...state, loggedIn: action.payload };
    case SET_USER_NAME:
      return { ...state, userName: action.payload };
    case RESET:
      return { ...initialState, fcmToken: state.fcmToken };
    case SET_PA_DETAILS:
      return { ...state, paDetails: action.payload };
    case SET_FCM_TOKEN:
      return { ...state, fcmToken: action.payload };
    case SET_STATES:
      return { ...state, states: action.payload };
    case UPDATE_CLINIC_ISFCM:
      const { loggedIn } = state;
      if (loggedIn) {
        const { clinicID, isFcm } = action.payload;
        const newClinicDetails = [...state.clinicDetails];
        const idx = newClinicDetails.findIndex(
          (clinic) => +clinic.clinicID === +clinicID
        );
        if (idx !== -1) {
          newClinicDetails[idx].isFcm = !!state.fcmToken && isFcm === "true";
        }
        return { ...state, clinicDetails: newClinicDetails };
      }
      return state;

    case UPDATE_CLINIC_FEE:
      const feeAmount = action.payload;
      const newClinicDetails = [...state.clinicDetails];
      newClinicDetails[0].clinicFees = +feeAmount;
      return { ...state, clinicDetails: newClinicDetails };

    default:
      return state;
  }
}

export default userReducer;
