import { useState } from "react";
import { useApi } from "../api";
import getErrorMessage from "../utils/getErrorMessage";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "../context/Snackbar.context";

const paymentMethods = {
  CARD: "Card",
  CASH: "Cash",
  JAZZCASH: "Jazzcash",
  EASYPAISA: "Easypaisa",
};

const useSubscription = () => {
  const api = useApi();
  const snackbar = useSnackbar();

  //   Unpaid Subscriptions
  const [unpaidSubscriptionsData, setUnpaidSubscriptionsData] = useState(null);

  const [unpaidSubscriptionsLoading, setUnpaidSubscriptionsLoading] =
    useState(false);

  const [unpaidSubscriptionsError, setUnpaidSubscriptionsError] =
    useState(null);

  const fetchUnpaidSubscriptions = () => {
    setUnpaidSubscriptionsLoading(true);
    setUnpaidSubscriptionsError(null);

    api
      .getUnPaidSubscription()
      .then((res) => {
        const data = res.data.body;
        setUnpaidSubscriptionsData(data);
      })
      .catch((err) => {
        const message = getErrorMessage(err);
        setUnpaidSubscriptionsError(message);
      })
      .finally(() => {
        setUnpaidSubscriptionsLoading(false);
      });
  };

  //   Paid Subscriptions
  const [paidSubscriptionsData, setPaidSubscriptionsData] = useState(null);

  const [paidSubscriptionsLoading, setPaidSubscriptionsLoading] =
    useState(false);

  const [paidSubscriptionsError, setPaidSubscriptionsError] = useState(null);

  const fetchPaidSubscriptions = () => {
    setPaidSubscriptionsLoading(true);
    setPaidSubscriptionsError(null);

    api
      .getPaidSubscription()
      .then((res) => {
        const data = res.data.body;
        setPaidSubscriptionsData(data);
      })
      .catch((err) => {
        const message = getErrorMessage(err);
        setPaidSubscriptionsError(message);
      })
      .finally(() => {
        setPaidSubscriptionsLoading(false);
      });
  };

  const [addSubscriptionLoading, setAddSubscriptionLoading] = useState(false);

  const addSubscriptionForm = useFormik({
    initialValues: {
      packageID: null,
      paymentMethod: paymentMethods.CASH,
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      packageID: Yup.number().required("Please choose a package"),
      paymentMethod: Yup.string().required("Please choose a payment method"),
    }),
    onSubmit: (values, { resetForm }) => {
      setAddSubscriptionLoading(true);
      api
        .addSubscription(values)
        .then((res) => {
          snackbar.show("success", "Subscription Added");
          resetForm();
          fetchUnpaidSubscriptions();
        })
        .catch((err) => {
          const message = getErrorMessage(err);
          snackbar.show("error", message);
        })
        .finally(() => {
          setAddSubscriptionLoading(false);
        });
    },
  });

  const [deleteSubscriptionLoading, setDeleteSubscriptionLoading] =
    useState(false);

  const deleteSubscription = (subscriptionID) => {
    setDeleteSubscriptionLoading(true);
    api
      .deleteSubscription({ subscriptionID })
      .then((res) => {
        snackbar.show("success", "Subscription deleted");
        fetchUnpaidSubscriptions();
      })
      .catch((err) => {
        const message = getErrorMessage(err);
        snackbar.show("error", message);
      })
      .finally(() => {
        setDeleteSubscriptionLoading(false);
      });
  };

  return {
    unpaidSubscriptions: {
      data: unpaidSubscriptionsData,
      loading: unpaidSubscriptionsLoading,
      error: unpaidSubscriptionsError,
      fetch: fetchUnpaidSubscriptions,
    },
    paidSubscriptions: {
      data: paidSubscriptionsData,
      loading: paidSubscriptionsLoading,
      error: paidSubscriptionsError,
      fetch: fetchPaidSubscriptions,
    },
    addSubscription: {
      form: addSubscriptionForm,
      loading: addSubscriptionLoading,
    },
    paymentMethods,
    deleteSubscription: {
      call: deleteSubscription,
      loading: deleteSubscriptionLoading,
    },
  };
};

export default useSubscription;
