import Button from "../../components/Button";

const CurrentPatient = ({
  session,
  data,
  onWaive,
  nextPatient,
  isLast = false,
}) => {
  return (
    <div className="rounded-[0.875rem] border-primary-60 border-[1px] bg-primary-0 p-[1rem] flex flex-row justify-between items-center">
      {!session?.isActive ? (
        <div className="flex flex-col gap-[0.25rem] justify-center items-start">
          <h4 className="heading-4 text-neutral-90 font-bold">
            No active session
          </h4>
        </div>
      ) : !data ? (
        <div className="flex flex-col gap-[0.25rem] justify-center items-start">
          <h4 className="heading-4 text-neutral-90 font-bold">
            Waiting on PA to send next patient
          </h4>
        </div>
      ) : (
        <>
          <div className="flex flex-col gap-[0.25rem] justify-center items-start">
            <p className="body-small text-neutral-60 font-semibold">
              Token Number: {data.token_number.toString().padStart(2, "0")}
            </p>
            <h4 className="heading-4 text-neutral-90 font-bold">
              {data.PatientName}
            </h4>
            <p className="body-small text-neutral-60 font-semibold">
              {data.Age} years, {data.Gender}
            </p>
          </div>
          <div className="flex flex-col gap-[0.25rem] justify-center items-center">
            {data?.isFeeWaived ? (
              <>
                <p className="body-micro text-neutral-60">Waived</p>
                <p className="body-small text-neutral-90 font-semibold">
                  Rs. {Intl.NumberFormat("en-US").format(data?.WaivedFeeAmount)}
                </p>
              </>
            ) : (
              <Button
                text="Waive Fee"
                variant="btn-tertiary"
                size="btn-medium"
                onClick={onWaive}
              />
            )}
            <Button
              text={isLast ? "Done" : "Send Next"}
              variant="btn-primary"
              size="btn-medium"
              onClick={nextPatient?.request}
              disabled={!data || nextPatient?.loading}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CurrentPatient;
