import React from "react";

const PatientQueue = ({ patients = [], handleClick = () => {} }) => {
  return (
    <>
      <table className="w-full">
        <thead>
          <tr className="border-b-[1px] border-neutral-10">
            <th className="body-large font-semibold text-center p-[0.75rem]">
              Token
            </th>
            <th className="body-large font-semibold text-left p-[0.75rem]">
              Patient Name
            </th>
            <th className="body-large font-semibold text-right p-[0.75rem] pr-0">
              Age / Gender
            </th>
          </tr>
        </thead>
        <tbody>
          {patients.map((patient, idx) => (
            <tr
              key={idx}
              className="border-b-[1px] border-neutral-10 hover:bg-neutral-20 hover:cursor-pointer transition-colors"
              onClick={() => handleClick(patient)}
            >
              <td className="body-large font-semibold p-[0.75rem] text-center">
                {patient?.token_number?.toString()?.padStart(2, "0")}
              </td>
              <td className="p-[0.75rem] flex flex-row justify-between items-center">
                <p className="body-large font-semibold">
                  {patient?.PatientName}
                </p>
              </td>
              <td>
                <div className="flex flex-col items-end">
                  <p className="body-small font-normal text-neutral-60">
                    {patient?.Age} years
                  </p>
                  <p className="body-micro font-normal text-neutral-60">
                    {patient?.Gender}
                  </p>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!patients.length && (
        <p className="body-large font-semibold w-full text-neutral-60 text-center">
          No current patients
        </p>
      )}
    </>
  );
};

export default PatientQueue;
