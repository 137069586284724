import React from "react";
import ScreenHeader from "../ScreenHeader";

const ScreenLayout = ({ title, children, back }) => {
  return (
    <div className="h-[100svh] w-full flex flex-col">
      <ScreenHeader title={title} back={back} />
      <div className="flex-1 overflow-y-scroll p-[1.25rem] flex flex-col gap-[2.25rem]">
        {children}
      </div>
    </div>
  );
};

export default ScreenLayout;
