import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/img/logo.svg";
import ArrowLeft from "../../assets/img/arrow-left.svg";

const AuthLayout = ({ title, children, bottomLink, withoutLogo = false }) => {
  const navigate = useNavigate();
  return (
    <div className="h-[100svh] w-full flex flex-col gap-4 p-[1.5rem] overflow-y-scroll">
      {withoutLogo ? (
        <button className="w-fit" onClick={() => navigate(-1)}>
          <img
            src={ArrowLeft}
            alt="go back"
            className="block my-6 w-[1rem] h-[1rem]"
          />
        </button>
      ) : (
        <img
          src={Logo}
          alt="app-logo"
          className="block my-6 w-[12.75rem] h-[3.375rem] self-center"
        />
      )}
      <h3 className="heading-3 w-[50%]">{title}</h3>
      {children}
      {bottomLink &&
        (bottomLink.goBack ? (
          <button
            onClick={() => navigate(-1)}
            className="btn btn-normal btn-tertiary btn-large"
          >
            Go Back
          </button>
        ) : (
          <Link
            className="btn btn-normal btn-tertiary btn-large"
            to={bottomLink.path}
          >
            {bottomLink.text}
          </Link>
        ))}
    </div>
  );
};

export default AuthLayout;
