import React from "react";

const CardButton = ({ text, onClick, active = false }) => {
  return (
    <button
      onClick={onClick}
      className={`flex-1 p-[0.5rem] rounded-[0.25rem] border-[1px] cursor-pointer font-semibold ${
        active
          ? "border-primary-70 text-primary-70 bg-primary-10"
          : "border-neutral-20 text-neutral-90"
      }`}
    >
      {text}
    </button>
  );
};

export default CardButton;
