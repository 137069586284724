import React from "react";

const IconButton = ({
  iconSrc,
  alt,
  onClick,
  variant = "primary",
  color = "primary",
  type = "normal",
  disabled = false,
  size = "icon-btn-xlarge",
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`icon-btn btn-${type} btn-${variant} ${size} ${
        color === "secondary"
          ? "bg-secondary-70"
          : color === "tertiary" && "bg-tertiary-70"
      }`}
    >
      <img src={iconSrc} alt={alt} />
    </button>
  );
};

export default IconButton;
