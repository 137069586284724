import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from "../../assets/img/calendar.svg";
import InputContainer from "../InputContainer";
import InputLabel from "../InputLabel";

const DateInput = ({
  touched,
  label,
  value,
  setValue,
  required = true,
  error,
  name,
  minDate,
  onFocus,
  maxDate,
}) => {
  const [focused, setFocused] = useState(false);

  return (
    <div>
      <InputLabel
        error={error && touched}
        label={label}
        focused={focused}
        required={required}
      />
      <InputContainer
        focused={focused}
        small
        error={error && touched}
        success={value && !error && touched}
      >
        <DatePicker
          id={name}
          name={name}
          className="input-area"
          selected={value}
          onChange={setValue}
          maxDate={maxDate}
          onFocus={() => {
            onFocus();
            setFocused(true);
          }}
          onBlur={() => {
            setFocused(false);
          }}
          minDate={minDate}
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          dateFormat="dd/MM/yyyy"
        />
        <label htmlFor={name}>
          <img
            src={CalendarIcon}
            alt="calendar-icon"
            className="w-[1.5rem] h-[1.5rem] absolute right-0 top-[50%] -translate-x-[50%] -translate-y-[50%]"
          />
        </label>
      </InputContainer>
      {/* TODO ERROR */}
    </div>
  );
};

export default DateInput;
