import React, { useEffect, useState } from "react";
import LogoWhite from "../../assets/img/logo-white.svg";

const Splash = () => {
  const [splash, setSplash] = useState(true);
  const [show, setShow] = useState(true);

  const splashTime = 1000;
  const animationTime = 150;

  useEffect(() => {
    setTimeout(() => {
      setSplash(false);
    }, splashTime);
    setTimeout(() => {
      setShow(false);
    }, splashTime + animationTime);
  }, []);

  if (!show) {
    return null;
  }
  return (
    <div
      className={`min-h-[100svh] w-full flex flex-col justify-center items-center bg-primary-70 ${
        splash ? "opacity-100" : "opacity-0"
      } transition-opacity fixed top-0 left-0 z-10`}
    >
      <img
        src={LogoWhite}
        alt="app-logo"
        className="block mb-3 w-[17rem] h-[4.5rem]"
      />
    </div>
  );
};

export default Splash;
