import React, { useState } from "react";
import InputContainer from "../InputContainer";
import InputError from "../InputError";
import InputLabel from "../InputLabel";
import Select from "react-select";

const SelectInput = ({
  placeholder,
  name,
  isHidden = false,
  value,
  setValue,
  label = "",
  required = true,
  options = [],
  valueGetter,
  textGetter,
  error,
  touched,
  onFocus,
}) => {
  const [focused, setFocused] = useState(false);

  const opts = [
    { label: "Select...", value: null },
    ...options.map((opt) => ({
      label: textGetter(opt),
      value: valueGetter(opt),
    })),
  ];

  const v = !value ? opts[0] : opts.find((opt) => opt?.value === value);

  return (
    <div className="w-full">
      <InputLabel
        error={error && touched && value}
        label={label}
        focused={focused}
        required={required}
      />
      <InputContainer
        focused={focused}
        error={error && touched && value}
        success={value && !error && touched}
      >
        <Select
          name={name}
          options={opts}
          className="input-area"
          onChange={(opt) => {
            setValue(opt?.value);
          }}
          onFocus={() => {
            setFocused(true);
            onFocus();
          }}
          onBlur={() => setFocused(false)}
          styles={{
            control: (base) => ({
              ...base,
              border: 0,
              boxShadow: "none",
              paddingLeft: "0.3rem",
              transition: "none",
              backgroundColor: "transparent",
            }),
          }}
          placeholder="Select"
          value={v}
        />
      </InputContainer>
      {error && touched && value && <InputError error={error} />}
    </div>
  );
};

export default SelectInput;
