import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthLayout from "../../components/AuthLayout";
import Button from "../../components/Button";
import OTPInput from "../../components/OTPInput";
import useVerifyOtp from "../../hooks/useVerifyOtp";

const VerifyOTP = () => {
  const verifyOtp = useVerifyOtp();

  const [time, setTime] = useState(60);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((time) => (time > 0 ? time - 1 : 0));
    }, 1000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <AuthLayout
      title="Enter OTP"
      bottomLink={{
        goBack: true,
      }}
    >
      {verifyOtp.onBoardingRole === "Doctor" ? (
        <p className="body-small font-semibold">
          You are signing up as a new clinic.
        </p>
      ) : (
        <>
          <p className="body-small font-semibold">
            This number is already linked with clinic
          </p>
          <p className="body-small">
            Didn't recognize?{" "}
            <Link to="/message" className="text-primary-70 font-semibold">
              Click Here
            </Link>
          </p>
        </>
      )}
      <p className="body-small">Enter the 6-digit code sent to your number.</p>
      <OTPInput
        otp={verifyOtp.form.values.otp}
        error={verifyOtp.form.errors.otp}
        setOtp={verifyOtp.form.handleChange("otp")}
      />
      <p className="body-micro">
        Didn't recieve the code?{" "}
        {!!time ? (
          <span className="font-semibold"> Resend in {time} seconds</span>
        ) : (
          <Link to="#" className="font-semibold text-primary-70">
            Resend code
          </Link>
        )}
      </p>
      <div className="mt-[2rem] flex flex-col gap-[0.5rem]">
        <Button
          text="Next"
          onClick={verifyOtp.form.handleSubmit}
          disabled={
            verifyOtp.loading ||
            verifyOtp.form.values.otp.length !== 6 ||
            !verifyOtp.form.isValid
          }
        />
      </div>
    </AuthLayout>
  );
};

export default VerifyOTP;
