import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { OnlyAlphabetsAndSpacesRegex, PhoneRegex } from "../utils/regex";
import { useApi } from "../api";
import getErrorMessage from "../utils/getErrorMessage";
import { useSelector } from "react-redux";
import { useSnackbar } from "../context/Snackbar.context";

const useAssignPA = () => {
  const api = useApi();
  const snackbar = useSnackbar();

  const { paDetails } = useSelector((state) => state.userReducer);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const form = useFormik({
    initialValues: {
      cellNumber: "",
      fullName: "",
      gender: "",
    },
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      cellNumber: Yup.string()
        .required("Cell number is required")
        .matches(PhoneRegex, { message: "Invalid cell number" }),
      fullName: Yup.string()
        .required("Full name is required")
        .matches(OnlyAlphabetsAndSpacesRegex, {
          message: "Only alphabets and spaces are allowed",
        }),
      gender: Yup.string()
        .required("Gender is required")
        .oneOf(["male", "female"], "Invalid gender"),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const body = { countryCode: "+92", ...values };
      if (paDetails.paExist) {
        api
          .replacePAByDoctor(body)
          .then(() => {
            snackbar.show("success", "Replaced PA successfully");
            resetForm();
          })
          .catch((error) => {
            const message = getErrorMessage(error);
            setError(message);
            snackbar.show("error", message);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        api
          .registerPAByDoctor(body)
          .then(() => {
            snackbar.show("success", "Assigned PA successfully");
            resetForm();
          })
          .catch((error) => {
            const message = getErrorMessage(error);
            setError(message);
            snackbar.show("error", message);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });

  const deletePA = () => {
    setLoading(true);
    api
      .deletePA()
      .then(() => {
        snackbar.show("success", "PA deleted successfully");
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        snackbar.show("error", message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    paDetails,
    loading,
    error,
    form,
    deletePA,
  };
};

export default useAssignPA;
