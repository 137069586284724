import { useEffect, useState } from "react";
import { useApi } from "../api";
import { useSnackbar } from "../context/Snackbar.context";
import getErrorMessage from "../utils/getErrorMessage";
import { useNotifications } from "../context/Notification.context";
import fcmTopicEnum from "../utils/fcmTopicEnum";

const usePAClinics = (clinicID) => {
  const api = useApi();
  const snackbar = useSnackbar();

  const [sessionLoading, setSessionLoading] = useState(false);
  const [sessionDetails, setSessionDetails] = useState(null);
  const [sessionError, setSessionError] = useState(null);

  const [queueLoading, setQueueLoading] = useState(false);
  const [queueDetails, setQueueDetails] = useState([]);
  const [queueError, setQueueError] = useState(null);

  const [sendingNextPatient, setSendingNextPatient] = useState(false);

  const reset = () => {
    setSessionLoading(false);
    setSessionDetails(null);
    setSessionError(null);
    setQueueLoading(false);
    setQueueDetails([]);
    setQueueError(null);
    setSendingNextPatient(false);
  };

  const notifications = useNotifications();

  const startSession = () => {
    api
      .startSession({ clinicID })
      .then((res) => {
        getSession();
        snackbar.show("success", "Session started successfully");
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        snackbar.show("error", message);
      });
  };

  const getSession = () => {
    reset();
    if (clinicID) {
      setSessionLoading(true);
      setSessionError(null);
      api
        .getSession({ clinicID })
        .then((res) => {
          setSessionDetails(res.data.body);
        })
        .catch((error) => {
          console.log(error);
          const message = getErrorMessage(error);
          snackbar.show("error", message);
          setSessionDetails(null);
          setSessionError(message);
        })
        .finally(() => {
          setSessionLoading(false);
        });
    }
  };

  useEffect(() => {
    const notif = notifications.notificationsWithoutPopup.at(
      notifications.notificationsWithoutPopup.length - 1
    );
    if (notif) {
      if (
        (notif?.data?.msg === fcmTopicEnum.END_SESSION ||
          notif?.data?.msg === fcmTopicEnum.START_SESSION ||
          notif?.data?.msg === fcmTopicEnum.ADD_PATIENT_QUEUE ||
          notif?.data?.msg === fcmTopicEnum.DELETE_PATIENT_QUEUE) &&
        +notif?.data?.clinicID === +clinicID
      ) {
        getSession();
      }
    }
    // eslint-disable-next-line
  }, [notifications.notificationsWithoutPopup.length]);

  useEffect(
    () => {
      const popupNotif = notifications.notifications.at(
        notifications.notifications.length - 1
      );

      if (popupNotif) {
        if (
          (popupNotif?.data?.msg === fcmTopicEnum.REQUEST_NEXT_PATIENT ||
            popupNotif?.data?.msg === fcmTopicEnum.WAIVE_FEE) &&
          +popupNotif?.data?.clinicID === +clinicID
        ) {
          getSession();
        }
      }
    },
    // eslint-disable-next-line
    [notifications.notifications.length]
  );

  useEffect(() => {
    getSession();
    // eslint-disable-next-line
  }, [clinicID]);

  const getQueue = () => {
    if (clinicID) {
      setQueueError(null);
      if (sessionDetails?.isActive) {
        setQueueLoading(true);
        api
          .getPatientQueue({ clinicID })
          .then((res) => {
            const data = res.data.body.patientQueue;
            setQueueDetails(data);
          })
          .catch((error) => {
            console.log(error);
            setQueueDetails([]);
            snackbar.show("error", "Error fetching queue");
            setQueueError("Error fetching queue");
          })
          .finally(() => {
            setQueueLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    getQueue();
    // eslint-disable-next-line
  }, [sessionDetails?.sessionId]);

  const sendNextPatient = (patientID, onSuccess = () => {}) => {
    setSendingNextPatient(true);
    api
      .sendNextPatient({ patientID, clinicID })
      .then(() => {
        snackbar.show("success", "Patient sent");
        getQueue();
        onSuccess();
      })
      .catch((error) => {
        const message = getErrorMessage(error);
        snackbar.show("error", message);
      })
      .finally(() => {
        setSendingNextPatient(false);
      });
  };

  let currentPatient;
  let uncheckedPatients = queueDetails || [];

  if (queueDetails?.length) {
    const firstPatient = queueDetails.at(0);
    if (firstPatient.isDoctorChecked === false) {
      [currentPatient, ...uncheckedPatients] = queueDetails;
    }
  }

  const value = {
    session: {
      loading: sessionLoading,
      data: sessionDetails,
      error: sessionError,
      get: getSession,
      start: startSession,
    },
    queue: {
      loading: queueLoading,
      currentPatient,
      uncheckedPatients,
      data: uncheckedPatients,
      error: queueError,
    },
    nextPatient: {
      loading: sendingNextPatient,
      send: sendNextPatient,
    },
  };

  return value;
};

export default usePAClinics;
