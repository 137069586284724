import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import InfoIcon from "../../assets/img/info.svg";
import MenuIcon from "../../assets/img/menu.svg";
import SettingsIcon from "../../assets/img/settings.svg";
import SubscriptionIcon from "../../assets/img/subscription.svg";
import Avatar from "../Avatar";
import { useDispatch, useSelector } from "react-redux";
import { reset } from "../../redux/actions";
import moment from "moment/moment";

const Drawer = () => {
  const {
    userName,
    role,
    clinicDetails: [
      { subscriptionType, subscriptionValid, subscriptionEndDate },
    ],
  } = useSelector((state) => state.userReducer);

  const [shown, setShown] = useState(false);
  const dispatch = useDispatch();

  const show = () => {
    setShown(true);
  };

  const hide = () => {
    setShown(false);
  };

  const logout = () => {
    dispatch(reset());
  };

  const drawerRef = useRef();

  return (
    <>
      <button type="button" onClick={show}>
        <img src={MenuIcon} alt="toggle-menu" />
      </button>
      {shown && (
        <div
          onClick={hide}
          className="w-full fixed min-h-[100svh] h-full overflow-y-scroll top-0 left-0 bg-[#0008] z-30"
        ></div>
      )}
      <div
        ref={drawerRef}
        className={`w-[14.25rem] p-[1.5rem] h-full overflow-y-scroll bg-primary-70 fixed top-0 left-0 ${
          shown ? "" : "-translate-x-full"
        } transition-transform flex flex-col gap-[1.5rem] z-40`}
      >
        <div className="mt-[3.75rem] w-full flex flex-col flex-1 items-center justify-center gap-[1.5rem]">
          <Avatar text={userName} />
          <div className="flex flex-col gap-[0.5rem] items-center w-full">
            <h4 className="heading-4 text-white">
              {role === "Doctor" ? "Dr. " : ""}
              {userName}
            </h4>
            {role === "Doctor" && subscriptionValid && (
              <div className="w-full mt-3 flex flex-col gap-[0.25rem]">
                <p className="text-center body-small text-primary-10">
                  Subscription Valid
                </p>
                <p className="text-center body-large text-white font-bold">
                  Type - {subscriptionType}
                </p>
                <p className="text-center body-micro text-primary-10 font-normal">
                  Validity Remaining:{" "}
                  {moment(new Date(subscriptionEndDate)).fromNow(true)}
                </p>
              </div>
            )}
          </div>
          <hr className="w-full border-white" />
          <div className="flex-1 flex flex-col w-full">
            <Link
              to={role === "Doctor" ? "/doctor/account" : "/pa/account"}
              className="p-[1rem] flex flex-row gap-[0.75rem] items-center w-full"
            >
              <img src={SettingsIcon} alt="settings" />
              <div className="text-white body-large">Settings</div>
            </Link>
            {role === "Doctor" && (
              <Link
                to="/doctor/subscription"
                className="p-[1rem] flex flex-row gap-[0.75rem] items-center w-full"
              >
                <img src={SubscriptionIcon} alt="settings" />
                <div className="text-white body-large">Subscription</div>
              </Link>
            )}
            {/* <Link
              to="/info"
              className="p-[1rem] flex flex-row gap-[0.75rem] items-center w-full"
            >
              <img src={InfoIcon} alt="info" />
              <div className="text-white body-large">Info</div>
            </Link> */}
          </div>
        </div>
        <button
          className="p-[1rem] flex flex-row gap-[0.75rem] items-center justify-center w-full text-white body-large"
          onClick={logout}
        >
          <img src={InfoIcon} alt="settings" />
          Log out
        </button>
      </div>
    </>
  );
};

export default Drawer;
