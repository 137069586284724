import React from "react";

const Avatar = ({ text = "" }) => {
  const initial = text.charAt(0)?.toUpperCase() || "";
  return (
    <div className="rounded-full w-[3rem] h-[3rem] bg-primary-0 text-primary-70 flex flex-col items-center justify-center">
      <h3 className="heading-3">{initial}</h3>
    </div>
  );
};

export default Avatar;
