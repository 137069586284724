import React from "react";
import { Link } from "react-router-dom";

const CardLink = ({ imageSrc, text, to }) => {
  return (
    <Link
      to={to}
      className="flex flex-col gap-0 rounded-[0.5rem] border-neutral-20 border-[1px] overflow-hidden"
    >
      <img src={imageSrc} alt="manage-clinic" />
      <div className="p-[1rem]">
        <h6 className="heading-6 font-semibold">{text}</h6>
      </div>
    </Link>
  );
};

export default CardLink;
