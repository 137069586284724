import React, { useState } from "react";
import ScreenLayout from "../../components/ScreenLayout";
import useField from "../../hooks/useField";
import TextInput from "../../components/TextInput";
import Textarea from "../../components/Textarea";
import PhoneInput from "../../components/PhoneInput";
import Button from "../../components/Button";

const Message = () => {
  const [loading, setLoading] = useState(false);

  const cellNumber = useField({
    initialValue: "",
    validatorConfig: {
      isPhone: true,
    },
  });

  const subject = useField({
    initialValue: "",
  });

  const message = useField({
    initialValue: "",
    validatorConfig: {
      required: true,
    },
  });

  const handleSubmit = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return (
    <ScreenLayout title="Submit Query">
      <h2 className="heading-2 w-[50%]">Send us a Message</h2>
      <div className="flex flex-col gap-[1.5rem]">
        <PhoneInput
          label="Cell Number"
          value={cellNumber.value.get()}
          setValue={cellNumber.value.set}
        />
        <TextInput
          placeholder="Enter Subject"
          label="Subject"
          required={false}
          value={subject.value.get()}
          setValue={subject.value.set}
        />
        <Textarea
          placeholder="Enter your query"
          label="Message"
          value={message.value.get()}
          setValue={message.value.set}
        />
        <div className="mt-[2rem] flex flex-col gap-[0.5rem]">
          <Button
            text="Submit"
            fullWidth
            size="btn-xlarge"
            disabled={loading}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </ScreenLayout>
  );
};

export default Message;
