export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN";
export const SET_ROLE = "SET_ROLE";
export const SET_OTP_TOKEN = "SET_OTP_TOKEN";
export const VERIFY_OTP_TOKEN = "VERIFY_OTP_TOKEN";
export const REGISTER_OTP_TOKEN = "REGISTER_OTP_TOKEN";
export const SET_CLINIC = "SET_CLINIC";
export const SET_ONBOARDING_ROLE = "SET_ONBOARDING_ROLE";
export const SET_ONBOARDING_CELL = "SET_ONBOARDING_CELL";
export const SET_LOGGED_IN = "SET_LOGGED_IN";
export const SET_OTP = "SET_OTP";
export const SET_USER_NAME = "SET_USER_NAME";
export const RESET = "RESET";
export const SET_PA_DETAILS = "SET_PA_DETAILS";
export const SET_FCM_TOKEN = "SET_FCM_TOKEN";
export const SET_STATES = "SET_STATES";
export const UPDATE_CLINIC_ISFCM = "UPDATE_CLINIC_ISFCM";
export const UPDATE_CLINIC_FEE = "UPDATE_CLINIC_FEE";

export const setAccessToken = (accessToken) => (dispatch) => {
  dispatch({
    type: SET_ACCESS_TOKEN,
    payload: accessToken,
  });
};
export const setRefreshToken = (refreshToken) => (dispatch) => {
  dispatch({
    type: SET_REFRESH_TOKEN,
    payload: refreshToken,
  });
};
export const setRole = (role) => (dispatch) => {
  dispatch({
    type: SET_ROLE,
    payload: role,
  });
};

export const setLoggedIn = (loggedIn) => (dispatch) => {
  dispatch({
    type: SET_LOGGED_IN,
    payload: loggedIn,
  });
};

export const generateOtpToken = (otpToken) => (dispatch) => {
  dispatch({
    type: SET_OTP_TOKEN,
    payload: otpToken,
  });
};

export const setOtp = (otp) => (dispatch) => {
  dispatch({
    type: SET_OTP,
    payload: otp,
  });
};

export const setOnBoardingRole = (onBoardingRole) => (dispatch) => {
  dispatch({
    type: SET_ONBOARDING_ROLE,
    payload: onBoardingRole,
  });
};

export const setOnBoardingCell = (onBoardingCell) => (dispatch) => {
  dispatch({
    type: SET_ONBOARDING_CELL,
    payload: onBoardingCell,
  });
};

export const verifyOtpToken = (VotpToken) => (dispatch) => {
  dispatch({
    type: VERIFY_OTP_TOKEN,
    payload: VotpToken,
  });
};

export const setClinic = (clinicDetails) => (dispatch) => {
  dispatch({
    type: SET_CLINIC,
    payload: clinicDetails,
  });
};

export const setUserName = (userName) => (dispatch) => {
  dispatch({
    type: SET_USER_NAME,
    payload: userName,
  });
};

export const reset = () => (dispatch) => {
  dispatch({
    type: RESET,
  });
};

export const setPADetails = (paDetails) => (dispatch) => {
  dispatch({
    type: SET_PA_DETAILS,
    payload: paDetails,
  });
};

export const setFCM = (fcmToken) => (dispatch) => {
  dispatch({
    type: SET_FCM_TOKEN,
    payload: fcmToken,
  });
};

export const setStates = (states) => (dispatch) => {
  dispatch({
    type: SET_STATES,
    payload: states,
  });
};

export const updateClinicIsFCM =
  ({ clinicID, isFcm }) =>
  (dispatch) => {
    dispatch({
      type: UPDATE_CLINIC_ISFCM,
      payload: { clinicID, isFcm },
    });
  };

export const updateClinicFee = (feeAmount) => (dispatch) => {
  dispatch({
    type: UPDATE_CLINIC_FEE,
    payload: feeAmount,
  });
};
