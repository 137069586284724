import { useEffect, useState } from "react";
import { useApi } from "../api";
import getErrorMessage from "../utils/getErrorMessage";

const useGetPatients = (clinicID) => {
  const api = useApi();

  const pageSize = 10;

  const [patients, setPatients] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [totalRecords, setTotalRecords] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);

  const [searchValue, setSearchValue] = useState("");

  const isEnd = pageNumber < totalPages;

  const fetch = (refetch = false) => {
    setLoading(true);
    if (refetch) {
      setPatients(null);
    }
    setError(null);
    api
      .getPatients({
        clinicID,
        pageNumber: refetch ? 1 : pageNumber + 1,
        pageSize,
        searchValue,
      })
      .then((res) => {
        const { totalRecords, totalPages, currentPage, patients } =
          res.data.body.result;

        setTotalRecords(totalRecords);
        setTotalPages(totalPages);
        setPageNumber(currentPage);
        setPatients((prev) => [...(refetch ? [] : prev || []), ...patients]);
      })
      .catch((error) => {
        setError(getErrorMessage(error));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reset = () => {
    setTotalRecords(null);
    setTotalPages(null);
    setPageNumber(0);
    setPatients(null);
    setSearchValue("");
  };

  useEffect(() => {
    fetch(true);
    // eslint-disable-next-line
  }, [searchValue]);

  const search = setSearchValue;

  return {
    patients,
    loading,
    error,
    totalRecords,
    totalPages,
    pageNumber,
    searchValue,
    isEnd,
    fetch,
    search,
    reset,
  };
};

export default useGetPatients;
