import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import userReducer from "./reducers";

const persistConfig = {
  key: "root",
  storage: storage,
};

const rootReducer = combineReducers({ userReducer });
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, applyMiddleware(thunk));

store.subscribe(() => {
  console.log(store.getState());
});
