import React from "react";
import EditIcon from "../../assets/img/edit.svg";

const SettingCard = ({
  left = null,
  title = "",
  description = "",
  editHandler,
}) => {
  return (
    <div className="flex flex-row gap-[0.75rem] items-center justify-between rounded-[0.5rem] p-[1rem] bg-white shadow-lg">
      {left}
      <div className="flex flex-col flex-1 gap-[0.25rem]">
        {title && (
          <p className="body-large text-neutral-90 font-bold">{title}</p>
        )}
        {description && (
          <p className="body-small text-neutral-60">{description}</p>
        )}
      </div>
      {editHandler && (
        <button className="p-[0.5rem]" onClick={editHandler}>
          <img src={EditIcon} alt="edit" />
        </button>
      )}
    </div>
  );
};

export default SettingCard;
