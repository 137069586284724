import React from "react";
import Button from "../Button";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const getSubscriptionString = (subscriptionRemaining) => {
  if (subscriptionRemaining) {
    const [id, val] = subscriptionRemaining.split("-");
    switch (id) {
      case "D":
        return val <= 3 ? `${val} days` : "";
      case "H":
        return `${val} hours`;
      case "M":
        return `${val} minutes`;
      default:
        return "";
    }
  } else {
    return "";
  }
};

const SubscriptionAlert = () => {
  const { clinicDetails } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();

  const [clinic] = clinicDetails;

  if (clinic) {
    const { subscriptionType, subscriptionRemaining, subscriptionValid } =
      clinic;

    const subscriptionString = getSubscriptionString(subscriptionRemaining);

    if (!subscriptionString && subscriptionValid) {
      return null;
    }

    return (
      <div className="border-[1px] border-neutral-20 p-[1rem] flex flex-row justify-between items-center">
        <div className="flex flex-row gap-[8px]">
          <div className="w-[0.5rem] h-[0.5rem] mt-[0.375rem] rounded-full bg-danger-20" />
          <p className="body-small text-neutral-60 font-normal">
            {subscriptionValid
              ? subscriptionType === "Trial"
                ? `Trial will expire in ${subscriptionString}`
                : `Subscription will expire in ${subscriptionString}`
              : "You have no active subscription"}
          </p>
        </div>
        <Button
          text="Subscribe"
          size="btn-medium"
          variant="btn-tertiary"
          onClick={() => {
            navigate("/doctor/subscription");
          }}
        />
      </div>
    );
  } else {
    return null;
  }
};

export default SubscriptionAlert;
