import React from "react";

const CircularIcon = ({ src }) => {
  return (
    <div className="w-[3rem] h-[3rem] rounded-full bg-primary-0 flex flex-col items-center justify-center">
      <img src={src} alt="icon" />
    </div>
  );
};

export default CircularIcon;
