import { useFormik } from "formik";
import * as Yup from "yup";
import getErrorMessage from "../utils/getErrorMessage";
import { useApi } from "../api";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const useVerifyOtp = () => {
  const api = useApi();
  const navigate = useNavigate();

  const onBoardingRole = useSelector(
    (state) => state.userReducer.onBoardingRole
  );

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const form = useFormik({
    initialValues: {
      otp: "",
    },
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      otp: Yup.string()
        .required("Otp is required")
        .matches(/^[0-9]{6}$/, { message: "OTP must be 6 digits" }),
    }),
    onSubmit: (values) => {
      setLoading(true);
      api
        .verifyOtp({ otpCode: +values.otp })
        .then(() => {
          setSuccess(true);
          if (onBoardingRole === "Doctor") {
            navigate("/onboarding/doctor/set-password");
          } else if (onBoardingRole === "PA") {
            navigate("/onboarding/pa/set-password");
          }
        })
        .catch((error) => {
          setError(getErrorMessage(error));
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return {
    onBoardingRole,
    loading,
    error,
    success,
    form,
  };
};

export default useVerifyOtp;
