import { useState } from "react";
import useValidator from "./useValidator";

const useField = ({ initialValue, validatorConfig = {} }) => {
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState(null);

  const validator = useValidator(validatorConfig);

  return {
    value: {
      get: () => value,
      set: setValue,
    },
    error: {
      get: () => error,
      set: setError,
    },
    validate: () => {
      const error = validator(value);
      setError(error);
      return !error;
    },
  };
};

export default useField;
