import { useFormik } from "formik";
import moment from "moment";
import { createContext, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useApi } from "../api";
import { useSnackbar } from "../context/Snackbar.context";
import getErrorMessage from "../utils/getErrorMessage";
import {
  OnlyAlphabetsAndSpacesRegex,
  PhoneRegex,
  PinRegex,
} from "../utils/regex";

const OnboardingContext = createContext();

export const OnboardingProvider = ({ children }) => {
  const api = useApi();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const { onBoardingRole, onBoardingCell: cellNumber } = useSelector(
    (state) => state.userReducer
  );

  // SET PASSWORD FORM
  const [passwordLoading, setPasswordLoading] = useState(false);
  const [passwordError, setPasswordError] = useState(null);
  const [password, setPassword] = useState(null);

  const passwordForm = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
      termsAccepted: false,
    },
    isInitialValid: false,
    validateOnChange: true,
    validateOnBlur: true,
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("Pin is required")
        .matches(/^\d+$/, { message: "Pin must be numeric" })
        .min(4, "Pin must have minimum 4 digits")
        .max(6, "Pin must have maximum 6 digits"),
        // .matches(PinRegex, {
        //   message:
        //     "Don't use an easy to guess pincode, containing consecutive or repeating digits e.g. 0000, 1234 etc.xs",
        // }),
      confirmPassword: Yup.string()
        .required("Confirm pin is required")
        .oneOf([Yup.ref("password"), null], "Pin must match"),
      termsAccepted: Yup.boolean().test({
        test: (value) => {
          if (onBoardingRole === "PA") {
            return !!value;
          } else {
            return true;
          }
        },
        message: "Must read and accept terms and conditions",
      }),
    }),
    onSubmit: (values) => {
      const { password } = values;
      if (onBoardingRole === "PA") {
        setPasswordLoading(true);
        api
          .registerPA({ password })
          .then(() => {
            api
              .logIn({
                countryCode: "+92",
                cellNumber,
                password,
              })
              .then((res) => {
                snackbar.show("success", "Successfully signed up as PA");
                navigate("/pa");
              })
              .catch((error) => {
                console.log(error);
                const message = getErrorMessage(error);
                snackbar.show("error", message);
              })
              .finally(() => {
                setPasswordLoading(false);
              });
          })
          .catch((error) => {
            console.log(error);
            const message = getErrorMessage(error);
            snackbar.show("error", message);
            setPasswordLoading(false);
            setPasswordError(message);
          });
      } else if (onBoardingRole === "Doctor") {
        setPassword(password);
        snackbar.show("success", "Signing up as Doctor");
        navigate("/onboarding/doctor/add-personal-info");
      }
    },
  });

  // ADD PERSONAL INFO
  const [personalInfo, setPersonalInfo] = useState(null);

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 21);

  const minDate = new Date();
  minDate.setFullYear(maxDate.getFullYear() - 110);

  const personalInfoForm = useFormik({
    initialValues: {
      fullName: "",
      dateOfBirth: maxDate,
      secondryCellNumber: "",
    },
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      fullName: Yup.string()
        .required("Full name is required")
        .matches(OnlyAlphabetsAndSpacesRegex, {
          message: "Only alphabets and spaces are allowed",
        })
        .min(3, "Must have minimum 3 characters"),
      dateOfBirth: Yup.date()
        .required("Date of Birth is required")
        .min(
          minDate,
          `Minumum date of birth is ${moment(minDate).format("YYYY-MM-DD")}`
        )
        .max(
          maxDate,
          `Maximum date of birth is ${moment(maxDate).format("YYYY-MM-DD")}`
        )
        .max(maxDate),
      secondryCellNumber: Yup.string().matches(PhoneRegex, {
        message: "Invalid phone",
      }),
    }),
    onSubmit: (values) => {
      let newValues = {
        ...values,
        dateOfBirth: moment(values.dateOfBirth).format("YYYY-MM-DD"),
      };
      if (!values.secondryCellNumber) {
        newValues.secondryCellNumber = "0000000000";
      }
      setPersonalInfo(newValues);
      snackbar.show("success", "Signing up as Doctor");
      navigate("/onboarding/doctor/register-clinic");
    },
  });

  // REGISTER CLINIC
  const [registerClinicLoading, setRegisterClinicLoading] = useState(false);
  const [registerClinicError, setRegisterClinicError] = useState(null);

  const registerClinicForm = useFormik({
    initialValues: {
      clinicName: "",
      clinicAddress: "",
      clinicState: "",
      clinicCity: "",
      speciality: "",
      feeAmount: "",
      termsAccepted: false,
    },
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      clinicName: Yup.string()
        .required("Clinic name is required")
        .min(3, "Clinic name must be atleast 3 characters long")
        .matches(OnlyAlphabetsAndSpacesRegex, {
          message: "Only alphabets and spaces are allowed",
        }),
      clinicAddress: Yup.string().required("Clinic address is required"),
      clinicState: Yup.string().required("Clinic state is required"),
      clinicCity: Yup.string().required("Clinic city is required"),
      speciality: Yup.string()
        .required("Speciality is required")
        .min(3, "Speciality must be atleast 3 characters long")
        .matches(OnlyAlphabetsAndSpacesRegex, {
          message: "Only alphabets and spaces are allowed",
        }),
      feeAmount: Yup.number()
        .required("Fee is required")
        .min(100, "Fee must comprise minimum 3 digits"),
      termsAccepted: Yup.boolean().isTrue(
        "Must read and accept terms and conditions"
      ),
    }),
    onSubmit: (values) => {
      const body = {
        password,
        gender: "male",
        secondryCountryCode: "+92",
        // country: "Pakistan",
        ...personalInfo,
        ...values,
      };
      setRegisterClinicLoading(true);
      api
        .registerDoctor(body)
        .then(() => {
          api
            .logIn({
              countryCode: "+92",
              cellNumber,
              password,
            })
            .then((res) => {
              snackbar.show("success", "Successfully signed up as Doctor");
              navigate("/doctor");
            })
            .catch((error) => {
              console.log(error);
              const message = getErrorMessage(error);
              snackbar.show("error", message);
            })
            .finally(() => {
              setRegisterClinicLoading(false);
            });
        })
        .catch((error) => {
          console.log(error);
          const message = getErrorMessage(error);
          snackbar.show("error", message);
          setRegisterClinicError(message);
          setRegisterClinicLoading(false);
        });
    },
  });

  const data = {
    onBoardingRole,
    password: {
      value: password,
      form: passwordForm,
      loading: passwordLoading,
      error: passwordError,
    },
    personalInfo: {
      value: personalInfo,
      form: personalInfoForm,
    },
    registerClinic: {
      form: registerClinicForm,
      loading: registerClinicLoading,
      error: registerClinicError,
    },
  };

  return (
    <OnboardingContext.Provider value={data}>
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboarding = () => {
  const onboarding = useContext(OnboardingContext);
  return onboarding;
};

export const withOnboarding = (Component) => () =>
  (
    <OnboardingProvider>
      <Component />
    </OnboardingProvider>
  );
