import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useApi } from "../../api";
import { reset } from "../../redux/actions";

const RefreshHOC = ({ children }) => {
  const api = useApi();
  const dispatch = useDispatch();

  const refresh = () => {
    api.refresh().catch((error) => {
      console.log(error);
      dispatch(reset());
    });
  };

  useEffect(() => {
    refresh();
    const interval = setInterval(refresh, 50 * 60 * 1000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, []);

  return children;
};

export default RefreshHOC;
