import React from "react";

const Button = ({
  text,
  onClick,
  variant = "btn-primary",
  type = "btn-normal",
  size = "btn-large",
  fullWidth = false,
  disabled = false,
}) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={`btn ${type} ${variant} ${size} ${fullWidth && "w-full"}`}
    >
      {text}
    </button>
  );
};

export default Button;
