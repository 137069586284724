import React from "react";
import Drawer from "../Drawer";

const TopBar = () => {
  return (
    <>
      <div className="p-[1.25rem] flex flex-row items-center justify-between border-b-[1px] border-b-neutral-10">
        <Drawer />
        {/* <Notifications /> */}
      </div>
    </>
  );
};

export default TopBar;
