import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useApi } from "./api";
import { useNotifications } from "./context/Notification.context";
import { OnboardingProvider } from "./context/Onboarding.context";
import { updateClinicIsFCM } from "./redux/actions";
import fcmTopicEnum from "./utils/fcmTopicEnum";
import AddPersonalInfo from "./views/AddPersonalInfo";
import AssignRole from "./views/AssignRole";
import CreatePatient from "./views/CreatePatient";
import DoctorAccountSettings from "./views/DoctorAccountSettings";
import Home from "./views/Home";
import ManageClinic from "./views/ManageClinic";
import Message from "./views/Message";
import NotificationScreen from "./views/NotificationScreen";
import PAAccountSettings from "./views/PAAccountSettings";
import PAHome from "./views/PAHome";
import Patients from "./views/Patients";
import PaymentHistory from "./views/PaymentHistory";
import RegisterClinic from "./views/RegisterClinic";
import SetPassword from "./views/SetPassword";
import Signin from "./views/Signin";
import Signup from "./views/Signup";
import Splash from "./views/Splash";
import Statistics from "./views/Statistics";
import Subscription from "./views/Subscription";
import VerifyOTP from "./views/VerifyOTP";

const App = () => {
  const api = useApi();

  const { onBoardingRole, role, loggedIn } = useSelector(
    (state) => state.userReducer
  );

  const notifications = useNotifications();
  const dispatch = useDispatch();

  useEffect(() => {
    if (role === "Doctor") {
      api.getPA().then().catch(console.log);
    }
    // eslint-disable-next-line
  }, [role]);

  useEffect(
    () => {
      const notif = notifications.notificationsWithoutPopup.at(
        notifications.notificationsWithoutPopup.length - 1
      );
      if (notif) {
        if (notif?.data?.msg === fcmTopicEnum.FCM_STATUS) {
          const { clinicID, isFcm } = notif.data;
          dispatch(updateClinicIsFCM({ clinicID, isFcm }));
        }
      }
    },
    // eslint-disable-next-line
    [notifications.notificationsWithoutPopup.length]
  );

  return (
    <>
      <Splash />
      <BrowserRouter>
        <Routes>
          {/* GENERIC ROUTES */}
          <Route path="message" element={<Message />} />

          {!loggedIn ? (
            <>
              {/* AUTH ROUTES */}
              <Route path="auth">
                <Route index element={<Navigate to="signin" />} />
                <Route path="signin" element={<Signin />} />
                <Route path="*" element={<Navigate to="signin" />} />
              </Route>

              {/* ONBOARDING ROUTES */}
              <Route path="onboarding">
                <Route index element={<Navigate to="signup" />} />
                <Route
                  path="signup"
                  element={
                    <OnboardingProvider>
                      <Signup />
                    </OnboardingProvider>
                  }
                />
                {onBoardingRole === "Doctor" ? (
                  // DOCTOR SPECIFIC ONBOARDING ROUTES
                  <Route path="doctor">
                    <Route index element={<Navigate to="verify-otp" />} />
                    <Route
                      path="verify-otp"
                      element={
                        <OnboardingProvider>
                          <VerifyOTP />
                        </OnboardingProvider>
                      }
                    />
                    <Route
                      path="set-password"
                      element={
                        <OnboardingProvider>
                          <SetPassword />
                        </OnboardingProvider>
                      }
                    />
                    <Route
                      path="add-personal-info"
                      element={
                        <OnboardingProvider>
                          <AddPersonalInfo />
                        </OnboardingProvider>
                      }
                    />
                    <Route
                      path="register-clinic"
                      element={
                        <OnboardingProvider>
                          <RegisterClinic />
                        </OnboardingProvider>
                      }
                    />
                    <Route path="*" element={<Navigate to="verify-otp" />} />
                  </Route>
                ) : (
                  onBoardingRole === "PA" && (
                    // PA SPECIFIC ONBOARDING ROUTES
                    <Route path="pa">
                      <Route index element={<Navigate to="verify-otp" />} />
                      <Route
                        path="verify-otp"
                        element={
                          <OnboardingProvider>
                            <VerifyOTP />
                          </OnboardingProvider>
                        }
                      />
                      <Route
                        path="set-password"
                        element={
                          <OnboardingProvider>
                            <SetPassword />
                          </OnboardingProvider>
                        }
                      />
                      <Route path="*" element={<Navigate to="verify-otp" />} />
                    </Route>
                  )
                )}
                <Route path="*" element={<Navigate to="signup" />} />
              </Route>
              <Route path="*" element={<Navigate to="auth" />} />
            </>
          ) : role === "Doctor" ? (
            <>
              {/* DOCTOR SPECIFIC ROUTES */}
              <Route path="doctor">
                <Route index element={<Home />} />
                <Route path="clinic">
                  <Route index element={<ManageClinic />} />
                  <Route path="patient">
                    <Route index element={<Patients />} />
                    <Route path="create" element={<CreatePatient />} />
                    <Route path="*" element={<Navigate to="" />} />
                  </Route>
                  <Route path="*" element={<Navigate to="" />} />
                </Route>
                <Route path="subscription" element={<Subscription />} />
                <Route path="assign-pa" element={<AssignRole />} />
                <Route path="statistics" element={<Statistics />} />
                <Route
                  path="payments/:cellNumber"
                  element={<PaymentHistory />}
                />
                <Route path="account" element={<DoctorAccountSettings />} />
                <Route path="*" element={<Navigate to="" />} />
              </Route>
              <Route path="*" element={<Navigate to="/doctor" />} />
            </>
          ) : (
            role === "PA" && (
              <>
                {/* PA SPECIFIC ROUTES */}
                <Route path="pa">
                  <Route index element={<PAHome />} />
                  <Route
                    path="clinic/:clinicID/patient/create"
                    element={<CreatePatient />}
                  />
                  <Route
                    path="notifications"
                    element={<NotificationScreen />}
                  />
                  <Route path="account" element={<PAAccountSettings />} />
                  <Route path="*" element={<Navigate to="" />} />
                </Route>
                <Route path="*" element={<Navigate to="/pa" />} />
              </>
            )
          )}
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default App;
