import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NextIcon from "../../assets/img/next.svg";
import PersonAddIcon from "../../assets/img/person-add.svg";
import Avatar from "../../components/Avatar";
import Button from "../../components/Button";
import IconButton from "../../components/IconButton";
import PatientQueue from "../../components/PatientQueue";
import Popup from "../../components/Popup";
import Spinner from "../../components/Spinner";
import TabView from "../../components/TabView";
import Toggle from "../../components/Toggle";
import TopBar from "../../components/TopBar";
import usePAClinics from "../../hooks/usePAClinics";
import useTabs from "../../hooks/useTabs";
import CurrentPatient from "./CurrentPatient";

const PAHome = () => {
  const { userName, clinicDetails } = useSelector((state) => state.userReducer);

  const navigate = useNavigate();
  const location = useLocation();

  const tabsData = clinicDetails.map((clinic) => ({
    label: `Dr. ${clinic.doctorName}`,
    id: clinic.clinicID,
  }));

  const idx = tabsData.findIndex((t) => t.id === +location.state?.clinicID);

  const tabs = useTabs(
    clinicDetails.map((clinic) => ({
      label: `Dr. ${clinic.doctorName}`,
      id: clinic.clinicID,
    })),
    idx !== -1 ? idx : 0
  );

  const clinicID = tabs.currentTab?.id;

  const paClinics = usePAClinics(clinicID);

  const getColor = () => {
    const activeTabIdx = tabs.tabs.findIndex(
      (tab) => tab.id === tabs.currentTab.id
    );
    switch (activeTabIdx) {
      case 1:
        return "secondary";
      case 2:
        return "tertiary";
      default:
        return "primary";
    }
  };

  const [activePatient, setActivePatient] = useState(null);

  const [startOpen, setStartOpen] = useState(false);

  return (
    <div>
      <TopBar />
      {/* Welcome Text */}
      <div className="p-[1.25rem] flex flex-col gap-[2.25rem] pb-[5.25rem]">
        <div className="flex flex-row items-center gap-[0.75rem]">
          <Avatar text={userName} />
          <div className="flex flex-col gap-[0.25rem]">
            <p className="body-large text-neutral-60">Welcome</p>
            <h3 className="heading-3 text-neutral-90">{userName}</h3>
          </div>
        </div>

        {/* Tabs */}
        {clinicDetails.length > 1 && <TabView tabs={tabs} />}

        {/* ACTIVE CLINIC */}
        <div className="flex flex-row justify-between">
          <h3 className="heading-4 font-bold">{tabs.currentTab.label}</h3>
          <Toggle
            disabled={paClinics.session.data?.isActive}
            value={paClinics.session.data?.isActive}
            onClick={() => {
              if (!paClinics?.session?.data?.isActive) {
                setStartOpen(true);
              }
            }}
          />
        </div>

        {paClinics.queue.loading ? (
          <Spinner />
        ) : (
          <>
            <CurrentPatient
              session={paClinics?.session?.data}
              data={paClinics.queue.currentPatient}
            />
            {paClinics.session.data?.isActive && (
              <PatientQueue
                patients={paClinics.queue.uncheckedPatients || []}
                handleClick={setActivePatient}
              />
            )}
          </>
        )}
      </div>
      <div className="fixed right-[1.25rem] bottom-[1.25rem]">
        <IconButton
          iconSrc={PersonAddIcon}
          alt="add-patient"
          color={getColor()}
          onClick={() => {
            navigate(`/pa/clinic/${tabs.currentTab.id}/patient/create`, {
              state: { doctorName: tabs.currentTab.label },
            });
          }}
        />
      </div>
      <Popup
        visible={!!activePatient}
        hide={() => setActivePatient(null)}
        icon={NextIcon}
      >
        <h4 className="heading-4 font-bold">Send Patient</h4>
        <p className="body-small text-neutral-60">
          Are you sure you want to send in {activePatient?.PatientName}?
        </p>
        <div className="flex flex-row justify-row gap-[1rem] w-full">
          <Button
            text="Cancel"
            fullWidth
            size="btn-large"
            variant="btn-secondary"
            onClick={() => {
              setActivePatient(null);
            }}
          />
          <Button
            text="Yes"
            fullWidth
            size="btn-large"
            disabled={paClinics.nextPatient.loading}
            onClick={() =>
              paClinics.nextPatient.send(activePatient?.PatientID, () => {
                setActivePatient(null);
              })
            }
          />
        </div>
      </Popup>
      <Popup visible={startOpen} hide={() => setStartOpen(false)}>
        <h4 className="heading-4 font-bold">Start Session</h4>
        <p className="body-small text-neutral-60">
          Are you sure you want to start session for {tabs.currentTab.label}{" "}
          now?
        </p>
        <div className="flex flex-row justify-row gap-[1rem] w-full">
          <Button
            text="Cancel"
            fullWidth
            size="btn-large"
            variant="btn-secondary"
            onClick={() => {
              setStartOpen(false);
            }}
          />
          <Button
            text="Yes"
            fullWidth
            size="btn-large"
            onClick={() => {
              paClinics.session.start();
              setStartOpen(false);
            }}
          />
        </div>
      </Popup>
    </div>
  );
};

export default PAHome;
