import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { useApi } from "../api";
import { useSnackbar } from "../context/Snackbar.context";
import getErrorMessage from "../utils/getErrorMessage";
import { useDispatch } from "react-redux";
import { updateClinicFee } from "../redux/actions";

const useUpdatePassword = ({ successCallback = () => {} }) => {
  const api = useApi();
  const snackbar = useSnackbar();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const form = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    isInitialValid: false,
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("Pin is required")
        .matches(/^\d+$/, { message: "Pin must be numeric" })
        .min(4, "Pin must have minimum 4 digits")
        .max(6, "Pin must have maximum 6 digits"),
      confirmPassword: Yup.string()
        .required("Confirm pin is required")
        .oneOf([Yup.ref("password"), null], "Pin must match"),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      api
        .updatePassword({ password: values.password })
        .then((res) => {
          snackbar.show("success", "Pin updated");
          successCallback();
          resetForm();
        })
        .catch((err) => {
          const message = getErrorMessage(err);
          // snackbar.show("error", message);
          // setError(message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return {
    loading,
    error,
    form,
  };
};

export default useUpdatePassword;
