import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PaymentsIcon from "../../assets/img/payments.svg";
import LockIcon from "../../assets/img/lock.svg";
import Avatar from "../../components/Avatar";
import Button from "../../components/Button";
import CircularIcon from "../../components/CircularIcon";
import ScreenLayout from "../../components/ScreenLayout";
import SettingCard from "../../components/SettingCard";
import { reset } from "../../redux/actions";
import Popup from "../../components/Popup";
import TextInput from "../../components/TextInput";
import useEditFee from "../../hooks/useEditFee";
import useUpdatePassword from "../../hooks/useUpdatePassword";

const DoctorAccountSettings = () => {
  const dispatch = useDispatch();

  const { userName, clinicDetails } = useSelector((state) => state.userReducer);

  const [clinic] = clinicDetails;

  const [editingFees, setEditingFees] = useState(false);
  const [editingPassword, setEditingPassword] = useState(false);

  const editFee = useEditFee({
    successCallback: () => {
      setEditingFees(false);
    },
  });

  const editPassword = useUpdatePassword({
    successCallback: () => {
      setEditingPassword(false);
    },
  });

  return (
    <ScreenLayout title="Account Settings">
      <div className="flex-1 flex flex-col gap-[0.75rem]">
        <SettingCard
          left={<Avatar text={userName} />}
          title={`Dr. ${userName}`}
        />
        {/* <SettingCard
          left={<CircularIcon src={CallIcon} />}
          title="Phone"
          // description="+92 123 4567"
        />
        <SettingCard
          left={<CircularIcon src={CallIcon} />}
          title="Secondary Phone"
          // description="+92 123 4567"
        /> */}
        <SettingCard
          left={<CircularIcon src={LockIcon} />}
          title="Password"
          description="Change/Reset Password"
          editHandler={() => {
            setEditingPassword(true);
          }}
        />
        {!!clinic && (
          <SettingCard
            left={<CircularIcon src={PaymentsIcon} />}
            title="Fees"
            description={`Rs. ${new Intl.NumberFormat("en-US").format(
              clinic.clinicFees
            )}`}
            editHandler={() => {
              setEditingFees(true);
            }}
          />
        )}
      </div>
      {/* Edit Fees */}
      <Popup visible={editingFees} hide={() => setEditingFees(false)}>
        <h4 className="heading-4 font-bold">Edit Clinic Fee</h4>
        <TextInput
          label="Fee amount"
          placeholder="Enter fee amount"
          required
          isNumeric
          name="feeAmount"
          value={editFee.form.values.feeAmount}
          setValue={editFee.form.handleChange("feeAmount")}
          onBlur={editFee.form.handleBlur("feeAmount")}
          onFocus={() => editFee.form.setFieldTouched("feeAmount", true)}
          error={editFee.form.errors.feeAmount}
          touched={editFee.form.touched.feeAmount}
        />
        <div className="flex flex-row justify-row gap-[1rem] w-full">
          <Button
            text="Cancel"
            fullWidth
            size="btn-large"
            variant="btn-secondary"
            onClick={() => {
              setEditingFees(false);
              editFee.form.resetForm();
            }}
          />
          <Button
            text="Submit"
            fullWidth
            size="btn-large"
            onClick={editFee.form.handleSubmit}
            disabled={editFee.loading || !editFee.form.isValid}
          />
        </div>
      </Popup>

      {/* Edit Password */}
      <Popup visible={editingPassword} hide={() => setEditingPassword(false)}>
        <h4 className="heading-4 font-bold">Change Password</h4>
        <TextInput
          label="New Pin"
          placeholder="Enter New Pin"
          isHidden
          required
          isNumeric
          name="password"
          value={editPassword.form.values.password}
          setValue={editPassword.form.handleChange("password")}
          onBlur={editPassword.form.handleBlur("password")}
          onFocus={() => editPassword.form.setFieldTouched("password", true)}
          error={editPassword.form.errors.password}
          touched={editPassword.form.touched.password}
        />
        <TextInput
          label="Confirm Pin"
          placeholder="Enter New Pin"
          isHidden
          required
          isNumeric
          name="confirmPassword"
          value={editPassword.form.values.confirmPassword}
          setValue={editPassword.form.handleChange("confirmPassword")}
          onBlur={editPassword.form.handleBlur("confirmPassword")}
          onFocus={() =>
            editPassword.form.setFieldTouched("confirmPassword", true)
          }
          error={editPassword.form.errors.confirmPassword}
          touched={editPassword.form.touched.confirmPassword}
        />
        <div className="flex flex-row justify-row gap-[1rem] w-full">
          <Button
            text="Cancel"
            fullWidth
            size="btn-large"
            variant="btn-secondary"
            onClick={() => {
              setEditingPassword(false);
              editPassword.form.resetForm();
            }}
          />
          <Button
            text="Submit"
            fullWidth
            size="btn-large"
            onClick={editPassword.form.handleSubmit}
            disabled={editPassword.loading || !editPassword.form.isValid}
          />
        </div>
      </Popup>
      <Button
        text="Log out"
        fullWidth
        size="btn-xlarge"
        variant="btn-secondary"
        type="btn-danger"
        onClick={() => {
          dispatch(reset());
        }}
      />
    </ScreenLayout>
  );
};

export default DoctorAccountSettings;
