import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useApi } from "../api";
import { useSnackbar } from "../context/Snackbar.context";
import getErrorMessage from "../utils/getErrorMessage";
import { PhoneRegex } from "../utils/regex";

const useSignup = () => {
  const api = useApi();
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const form = useFormik({
    initialValues: {
      cellNumber: "",
    },
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      cellNumber: Yup.string()
        .required("Required")
        .matches(PhoneRegex, { message: "Invalid cell number" }),
    }),
    onSubmit: ({ cellNumber }, { resetForm }) => {
      setLoading(true);
      api
        .generateOtp({
          countryCode: "+92",
          cellNumber,
        })
        .then((res) => {
          setSuccess(true);
          resetForm();

          const { role } = res.data.body;

          if (role === "PA") {
            snackbar.show("success", "Signing up as PA");
            navigate("/onboarding/pa/set-password");
          }
          if (role === "Doctor") {
            snackbar.show("success", "Signing up as doctor");
            navigate("/onboarding/doctor/set-password");
          }
        })
        .catch((error) => {
          console.log(error);
          const message = getErrorMessage(error);
          snackbar.show("error", message);
          setError(message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return {
    loading,
    error,
    success,
    form,
  };
};

export default useSignup;
