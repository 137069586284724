import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import AssignRoleImage from "../../assets/img/assign-role.svg";
import ManageClinicImage from "../../assets/img/manage-clinic.svg";
import PatientsImage from "../../assets/img/patients.svg";
import StatisticsImage from "../../assets/img/statistics.svg";
import Avatar from "../../components/Avatar";
import CardLink from "../../components/CardLink";
import Spinner from "../../components/Spinner";
import SubscriptionAlert from "../../components/SubscriptionAlert";
import TopBar from "../../components/TopBar";
import useDoctorDashboard from "../../hooks/useDoctorDashboard";

const Home = () => {
  const { userName, clinicDetails } = useSelector((state) => state.userReducer);

  const doctorDashboard = useDoctorDashboard();
  const navigate = useNavigate();

  const { clinicFees } = clinicDetails[0];

  return (
    <div>
      <TopBar />
      {/* Welcome Text */}
      <div className="p-[1.25rem] flex flex-col gap-[2.25rem]">
        <div className="flex flex-row justify-between items-end">
          <div className="flex flex-row items-center gap-[0.75rem]">
            <Avatar text={userName} />
            <div className="flex flex-col gap-[0.25rem]">
              <p className="body-large text-neutral-60">Welcome</p>
              <h3 className="heading-3 text-neutral-90">Dr. {userName}</h3>
            </div>
          </div>
          {/* Date and Time */}
          {/* <div className="flex flex-col justify-center items-end gap-[0.25rem]">
            <p className="body-micro text-neutral-60">{timer.dateString}</p>
            <h6 className="heading-6 font-bold text-neutral-60">
              {timer.timeString}
            </h6>
          </div> */}
        </div>
        <SubscriptionAlert />
        {/* Current Patient and Token */}
        <div
          onClick={() => {
            navigate("/doctor/clinic");
          }}
          className="cursor-pointer bg-secondary-0 border-secondary-20 border-[1px] p-[1.5rem] rounded-[0.5rem] flex flex-col gap-[0.5rem]"
        >
          {doctorDashboard.loading ? (
            <Spinner />
          ) : !doctorDashboard.data ? (
            <h4 className="heading-4 text-neutral-90">No active session</h4>
          ) : (
            <>
              <div className="flex flex-row justify-between">
                <h3 className="heading-3 text-neutral-90">Token Number</h3>
                <h3 className="heading-3 text-neutral-90">
                  {doctorDashboard.data.dashboard.PatientsChecked}/
                  {doctorDashboard.data.dashboard.PatientsChecked +
                    doctorDashboard.data.dashboard.PatientsUnchecked}
                </h3>
              </div>
              <div className="flex flex-row justify-between">
                <p className="body-small text-neutral-60">
                  {doctorDashboard.data.dashboard.PatientsUnchecked} patients
                  remaining
                </p>
                <h6 className="heading-6 text-neutral-90 font-semibold">
                  {doctorDashboard.data.currency}{" "}
                  {new Intl.NumberFormat("en-US").format(
                    doctorDashboard.data.dashboard.PatientPayments
                  )}
                  /
                  {new Intl.NumberFormat("en-US").format(
                    doctorDashboard.data.dashboard.PatientPayments +
                      doctorDashboard.data.dashboard.PatientsUnchecked *
                        clinicFees
                  )}
                </h6>
              </div>
            </>
          )}
        </div>
        {/* Card Links */}
        <div className="grid grid-cols-2 gap-[1rem] justify-between">
          <CardLink
            imageSrc={ManageClinicImage}
            text="Manage Clinic"
            to="/doctor/clinic"
          />
          <CardLink
            imageSrc={StatisticsImage}
            text="Statistics"
            to="/doctor/statistics"
          />
          <CardLink
            imageSrc={PatientsImage}
            text="Patients"
            to="/doctor/clinic/patient"
          />
          <CardLink
            imageSrc={AssignRoleImage}
            text="Assign Role"
            to="/doctor/assign-pa"
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
