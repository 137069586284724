import React from "react";
import { createPortal } from "react-dom";

const Snackbar = ({
  type = "success",
  message = "Success",
  shown = false,
  hide = () => {},
}) => {
  return createPortal(
    <div
      className={`fixed bottom-0 left-0 p-[16px] w-full ${
        shown ? "z-40" : "-z-10"
      }`}
    >
      <div
        className={`p-[16px] w-full bg-neutral-90 text-white font-semibold body-small flex-1 rounded-[4px] flex flex-row gap-[1rem] transition-opacity shadow-md ${
          shown ? "opacity-100" : "opacity-0"
        }`}
      >
        <p className="flex-1">{message}</p>
        <button className="text-primary-30 cursor-pointer" onClick={hide}>
          Okay
        </button>
      </div>
    </div>,
    document.getElementById("snackbar-root")
  );
};

export default Snackbar;
