const fcmTopicEnum = {
  REQUEST_NEXT_PATIENT: "Request Next Patient",
  SEND_NEXT_PATIENT: "Send Next Patient",
  ADD_PATIENT_QUEUE: "Add Patient Queue",
  DELETE_PATIENT_QUEUE: "Delete Patient Queue",
  START_SESSION: "Start Session",
  END_SESSION: "End Session",
  WAIVE_FEE: "Waive Fee",
  FCM_STATUS: "Fcm Status",
};

export default fcmTopicEnum;
