import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import App from "./App";
import AddToHomeSreen from "./components/AddToHomeScreen";
import InternetAvailability from "./components/InternetAvailability";
import RefreshHOC from "./components/RefreshHOC";
import NotificationProvider from "./context/Notification.context";
import { SnackbarProvider } from "./context/Snackbar.context";
import "./index.css";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

let persistor = persistStore(store);

document.documentElement.style.setProperty(
  "--vh",
  window.innerHeight * 0.01 + "px"
);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <SnackbarProvider>
      <InternetAvailability>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <NotificationProvider>
              <RefreshHOC>
                <App />
                <AddToHomeSreen />
              </RefreshHOC>
            </NotificationProvider>
          </PersistGate>
        </Provider>
      </InternetAvailability>
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
