import React from "react";
import AuthLayout from "../../components/AuthLayout";
import Button from "../../components/Button";
import PhoneInput from "../../components/PhoneInput";
import useSignup from "../../hooks/useSignup";

const Signup = () => {
  const signup = useSignup();

  return (
    <AuthLayout
      title="Sign Up"
      bottomLink={{
        path: "/auth/signin",
        text: "Sign in",
      }}
    >
      <PhoneInput
        name="cellNumber"
        value={signup.form.values.cellNumber}
        touched={signup.form.touched.cellNumber}
        error={signup.form.errors.cellNumber}
        required
        setValue={signup.form.handleChange("cellNumber")}
        onBlur={signup.form.handleBlur("cellNumber")}
        onFocus={() => signup.form.setFieldTouched("cellNumber", true)}
        label="Mobile Number"
      />
      <div className="mt-[2rem] flex flex-col gap-[0.5rem]">
        <Button
          text="Continue"
          onClick={signup.form.handleSubmit}
          disabled={signup.loading || !signup.form.isValid}
        />
      </div>
    </AuthLayout>
  );
};

export default Signup;
