import React, { useState, useEffect } from "react";
import ScreenLayout from "../../components/ScreenLayout";
import ArrowUpIcon from "../../assets/img/arrow-up.svg";
import ArrowDownIcon from "../../assets/img/arrow-down.svg";
import ChipSelect from "../../components/ChipSelect";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useApi } from "../../api";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const StatisticCard = ({ title, children, variant = "secondary" }) => {
  return (
    <div
      className={`p-[1.25rem] flex flex-col justify-between gap-[0.5rem] flex-1 rounded-[0.5rem] border-[1px] ${
        variant === "secondary"
          ? "bg-secondary-0 border-secondary-20"
          : "bg-tertiary-0 border-tertiary-20"
      }`}
    >
      <p className="body-small text-neutral-60 font-semibold">{title}</p>
      {children}
    </div>
  );
};

const Amount = ({ currency, amount }) => {
  return (
    <div className="flex flex-row gap-[0.25rem] items-end">
      <p className="body-micro font-bold text-neutral-90">{currency}</p>
      <h4 className="heading-4 font-bold text-neutral-90">
        {new Intl.NumberFormat("en-US").format(amount)}
      </h4>
    </div>
  );
};

const Value = ({ value }) => {
  return (
    <div className="flex flex-row gap-[0.25rem] items-end">
      <h4 className="heading-4 font-bold text-neutral-90">
        {new Intl.NumberFormat("en-US").format(value)}
      </h4>
    </div>
  );
};

const Trend = ({ current, previous }) => {
  const percentage =
    previous !== 0 ? ((current - previous) / previous) * 100 : current;

  const rise = percentage > 0;
  return (
    <div
      className={`p-[0.25rem] ${
        rise ? "bg-success-0 border-success-40" : "bg-danger-0 border-danger-20"
      } border-[1px] rounded-full flex flex-row gap-[0.25rem] justify-center`}
    >
      <img
        src={rise ? ArrowUpIcon : ArrowDownIcon}
        alt={rise ? "arrow-up" : "arrow-down"}
      />
      <div
        className={`${
          rise ? "text-success-40" : "text-danger-20"
        } body-micro font-semibold`}
      >
        {rise ? "+" : "-"}
        {new Intl.NumberFormat("en-US").format(Math.round(percentage))}%
      </div>
    </div>
  );
};

const Statistics = () => {
  const api = useApi();

  const options = {
    plugins: {
      title: {
        display: true,
        text: "Graphs",
      },
    },
    responsive: true,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        ticks: {
          precision: 0,
        },
        stacked: true,
      },
    },
  };

  function formatDate(date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .split("T")[0];
  }

  function getMonday(date) {
    var day = date.getDay() || 7;
    if (day !== 1) date.setHours(-24 * (day - 1));
    return date;
  }

  function addDays(date, days) {
    var dateCopy = new Date(date);
    dateCopy.setUTCDate(date.getUTCDate() + days);
    return dateCopy;
  }

  const [statsData, setStatsData] = useState([]);
  const [currency, setCurrency] = useState();
  const [mode, setMode] = useState("today");
  function getStatistics() {
    const currentDate = new Date();
    if (mode === "today") {
      const startDate = formatDate(currentDate);
      const endDate = formatDate(currentDate);

      api
        .getStatistics({ statisticType: "Date Range", startDate, endDate })
        .then((res) => {
          setCurrency(res.data.body.currency);
          setStatsData(res.data.body.statistics);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (mode === "week") {
      let startDate = getMonday(currentDate);
      const endDate = formatDate(addDays(startDate, 6));

      startDate = formatDate(startDate);

      api
        .getStatistics({ statisticType: "Date Range", startDate, endDate })
        .then((res) => {
          setCurrency(res.data.body.currency);
          setStatsData(res.data.body.statistics);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (mode === "month") {
      const startDate = formatDate(
        new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
      );
      const endDate = formatDate(
        new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)
      );

      api
        .getStatistics({ statisticType: "Date Range", startDate, endDate })
        .then((res) => {
          setCurrency(res.data.body.currency);
          setStatsData(res.data.body.statistics);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      const startDate = formatDate(new Date(currentDate.getFullYear(), 0, 1));
      const endDate = formatDate(new Date(currentDate.getFullYear() + 1, 0, 0));

      api
        .getStatistics({ statisticType: "Date Range", startDate, endDate })
        .then((res) => {
          setCurrency(res.data.body.currency);
          setStatsData(res.data.body.statistics);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  useEffect(() => {
    getStatistics();
    // eslint-disable-next-line
  }, [mode]);

  const [graphData, setGraphData] = useState([]);
  const [flag, setFlag] = useState(2);
  function getGraphs() {
    if (flag === 1) {
      api
        .getGraphs({ graphType: "Year" })
        .then((res) => {
          setGraphData(res.data.body.graphs);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      api
        .getGraphs({ graphType: "Week" })
        .then((res) => {
          setGraphData(res.data.body.graphs);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }
  useEffect(() => {
    getGraphs();
    // eslint-disable-next-line
  }, [flag]);
  const labels = graphData.map((el) => el.SDesc);

  const data = {
    labels,
    datasets: [
      {
        label: "Total Patients",
        data: graphData.map((e) => e.TotalPatients),
        backgroundColor: "rgb(255, 99, 132)",
      },
      {
        label: "New Patients",
        data: graphData.map((e) => e.UniquePatients),
        backgroundColor: "rgb(75, 192, 192)",
      },
    ],
  };

  const data2 = {
    labels,
    datasets: [
      {
        label: "CashFlow",
        data: graphData.map((e) => e.CashFlow),
        backgroundColor: "rgb(255, 99, 132)",
      },
    ],
  };

  return (
    <ScreenLayout title="Statistics">
      <div className="flex flex-row justify-between items-center">
        <h3 className="heading-3">Statistics</h3>
        <ChipSelect
          value={mode}
          setValue={setMode}
          options={[
            {
              text: "Today",
              value: "today",
            },
            {
              text: "This week",
              value: "week",
            },
            {
              text: "This month",
              value: "month",
            },
            {
              text: "This year",
              value: "year",
            },
          ]}
        />
      </div>
      <div className="grid grid-cols-1 gap-[1rem] justify-between">
        <StatisticCard variant="secondary" title="Cash Flow">
          <Amount
            currency={currency ? currency : "Rs."}
            amount={statsData[0] ? statsData[0].CashFlow : 0}
          />
          <Trend
            current={statsData[0] ? statsData[0].CashFlow : 0}
            previous={statsData[1] ? statsData[1].CashFlow : 0}
          />
        </StatisticCard>
        {/* <StatisticCard variant="secondary" title="Cash Flow - Last Week">
          <Amount amount={12450} />
          <Trend percentage={-9.3} />
        </StatisticCard> */}
        <StatisticCard variant="tertiary" title="Total Patients">
          <Value value={statsData[0] ? statsData[0].TotalPatients : 0} />
          <Trend
            current={statsData[0] ? statsData[0].TotalPatients : 0}
            previous={statsData[1] ? statsData[1].TotalPatients : 0}
          />
        </StatisticCard>
        {/* <StatisticCard variant="tertiary" title="Total Patients - Last Week">
          <h4 className="heading-4 font-bold text-neutral-90">670</h4>
          <Trend percentage={-9.3} />
        </StatisticCard> */}
        <StatisticCard variant="secondary" title="New Patients">
          <Value value={statsData[0] ? statsData[0].UniquePatients : 0} />
          <Trend
            current={statsData[0] ? statsData[0].UniquePatients : 0}
            previous={statsData[1] ? statsData[1].UniquePatients : 0}
          />
        </StatisticCard>
        {/* <StatisticCard variant="secondary" title="New Patients - Last Week">
          <h4 className="heading-4 font-bold text-neutral-90">18</h4>
          <Trend percentage={+9.3} />
        </StatisticCard> */}
      </div>
      <div className="flex flex-row justify-between">
        <h3 className="heading-3">Graph View</h3>
        <ChipSelect
          value={flag}
          setValue={setFlag}
          options={[
            {
              text: "Weekly",
              value: 2,
            },
            {
              text: "Yearly",
              value: 1,
            },
          ]}
        />
      </div>
      <Bar style={{ lineClamp: false }} options={options} data={data} />
      <Bar options={options} data={data2} />
    </ScreenLayout>
  );
};

export default Statistics;
