import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Spinner";
import useGetClinicDashboard from "../../hooks/useGetClinicDashboard";
import { useSelector } from "react-redux";

const ClinicDashboard = ({ active }) => {
  const clinicDashboard = useGetClinicDashboard(active);
  const { clinicDetails } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();

  const { clinicFees } = clinicDetails[0];

  return clinicDashboard.loading ? (
    <Spinner />
  ) : (
    <div className="flex flex-wrap flex-row gap-[0.75rem] justify-between w-full">
      <div
        onClick={() => {
          navigate("/doctor/statistics");
        }}
        className="p-[1.25rem] flex flex-col justify-between gap-[0.5rem] flex-1 rounded-[0.5rem] bg-secondary-0 border-[1px] border-secondary-20 w-full sm:w-auto"
      >
        <p className="body-small cursor-pointer text-neutral-60 font-semibold">
          Cash Flow
        </p>
        <div>
          <p className="body-micro font-bold text-neutral-90">
            {clinicDashboard.data?.currency}
          </p>
          <h4 className="heading-4 font-bold text-neutral-90">
            {active
              ? new Intl.NumberFormat("en-US").format(
                  clinicDashboard.data?.dashboard?.PatientPayments
                )
              : 0}
            /
            {active
              ? new Intl.NumberFormat("en-US").format(
                  clinicDashboard.data?.dashboard?.PatientPayments +
                    clinicDashboard.data?.dashboard?.PatientsUnchecked *
                      clinicFees
                )
              : 0}
          </h4>
        </div>
      </div>
      {/* <div
        onClick={() => {
          navigate("/doctor/statistics");
        }}
        className="p-[1.25rem] flex flex-col justify-between gap-[0.5rem] flex-1 rounded-[0.5rem] bg-secondary-0 border-[1px] border-secondary-20 w-full sm:w-auto"
      >
        <p className="body-small cursor-pointer text-neutral-60 font-semibold">
          Fee Waived
        </p>
        <div>
          <p className="body-micro font-bold text-neutral-90">
            {clinicDashboard.data?.currency}
          </p>
          <h4 className="heading-4 font-bold text-neutral-90">
            {active
              ? new Intl.NumberFormat("en-US").format(
                  clinicDashboard.data?.dashboard?.PatientPayments
                )
              : 0}
            /
            {active
              ? new Intl.NumberFormat("en-US").format(
                  clinicDashboard.data?.dashboard?.PatientPayments +
                    clinicDashboard.data?.dashboard?.PatientsUnchecked *
                      clinicFees
                )
              : 0}
          </h4>
        </div>
      </div> */}
      <div
        onClick={() => {
          navigate("/doctor/statistics");
        }}
        className="p-[1.25rem] flex flex-col justify-between gap-[0.5rem] flex-1 rounded-[0.5rem] bg-tertiary-0 border-[1px] border-tertiary-20 w-full sm:w-auto"
      >
        <p className="body-small cursor-pointer text-neutral-60 font-semibold">
          Total Patients
        </p>
        <h4 className="heading-4 font-bold text-neutral-90">
          {active ? clinicDashboard.data?.dashboard?.PatientsChecked : 0}/
          {active
            ? clinicDashboard.data?.dashboard?.PatientsUnchecked +
              clinicDashboard.data?.dashboard?.PatientsChecked
            : 0}
        </h4>
      </div>
      <div
        onClick={() => {
          navigate("/doctor/statistics");
        }}
        className="p-[1.25rem] flex flex-col justify-between gap-[0.5rem] flex-1 rounded-[0.5rem] bg-primary-0 border-[1px] border-primary-20 w-full sm:w-auto"
      >
        <p className="body-small cursor-pointer text-neutral-60 font-semibold">
          New Patients
        </p>
        <h4 className="heading-4 font-bold text-neutral-90">
          {active ? clinicDashboard.data?.dashboard?.UniquePatients : 0}
        </h4>
      </div>
    </div>
  );
};

export default ClinicDashboard;
