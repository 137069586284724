import React from "react";
import AuthLayout from "../../components/AuthLayout";
import Button from "../../components/Button";
import DateInput from "../../components/DateInput";
import PhoneInput from "../../components/PhoneInput";
import TextInput from "../../components/TextInput";
import { useOnboarding } from "../../context/Onboarding.context";

const AddPersonalInfo = () => {
  const { personalInfo } = useOnboarding();

  const maxDate = new Date();
  maxDate.setFullYear(maxDate.getFullYear() - 21);

  const minDate = new Date();
  minDate.setFullYear(maxDate.getFullYear() - 110);

  return (
    <AuthLayout
      title="Personal Information"
      withoutLogo={true}
      bottomLink={{
        goBack: true,
      }}
    >
      <TextInput
        placeholder="Enter Full Name"
        label="Full Name"
        required
        name="fullName"
        setValue={personalInfo.form.handleChange("fullName")}
        appendStart="Dr. "
        value={personalInfo.form.values.fullName}
        isAlphabetic
        onBlur={personalInfo.form.handleBlur("fullName")}
        onFocus={() => personalInfo.form.setFieldTouched("fullName", true)}
        error={personalInfo.form.errors.fullName}
        touched={personalInfo.form.touched.fullName}
      />
      <DateInput
        label="Date of Birth"
        required
        name="dateOfBirth"
        value={personalInfo.form.values.dateOfBirth}
        setValue={(value) =>
          personalInfo.form.setFieldValue("dateOfBirth", value)
        }
        onBlur={personalInfo.form.handleBlur("dateOfBirth")}
        onFocus={() => personalInfo.form.setFieldTouched("dateOfBirth", true)}
        error={personalInfo.form.errors.dateOfBirth}
        touched={personalInfo.form.touched.dateOfBirth}
        minDate={minDate}
        maxDate={maxDate}
      />
      <PhoneInput
        name="secondryCellNumber"
        label="Secondary Number"
        value={personalInfo.form.values.secondryCellNumber}
        setValue={personalInfo.form.handleChange("secondryCellNumber")}
        onBlur={personalInfo.form.handleBlur("secondryCellNumber")}
        onFocus={() =>
          personalInfo.form.setFieldTouched("secondryCellNumber", true)
        }
        error={personalInfo.form.errors.secondryCellNumber}
        touched={personalInfo.form.touched.secondryCellNumber}
      />
      <div className="mt-[32px] flex flex-col gap-[8px]">
        <Button
          text="Next"
          onClick={personalInfo.form.handleSubmit}
          disabled={!personalInfo.form.isValid}
        />
      </div>
    </AuthLayout>
  );
};

export default AddPersonalInfo;
