import { useEffect, useState } from "react";
import { useApi } from "../api";

const useGetClinicDashboard = (active) => {
  const api = useApi();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const fetch = () => {
    setLoading(true);
    api
      .getClinicDashboard()
      .then((res) => {
        setData(res.data.body);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line
  }, [active]);

  return {
    loading,
    data,
    fetch,
    error: !loading && !data,
  };
};

export default useGetClinicDashboard;
