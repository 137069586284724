// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getMessaging, onMessage } from "firebase/messaging";
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBZYgsJTiUUjldoXZHnG7n5DIAaiZervDY",
  authDomain: "my-clinic-c2bae.firebaseapp.com",
  projectId: "my-clinic-c2bae",
  storageBucket: "my-clinic-c2bae.appspot.com",
  messagingSenderId: "241721158374",
  appId: "1:241721158374:web:78507bb7e5e90065c7e927",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const messaging = getMessaging(app);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
