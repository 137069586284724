import React from "react";

const CardMessage = ({ title, message, iconSrc }) => {
  return (
    <div className="p-[1rem] rounded-[0.5rem] border-[1px] border-primary-70 flex flex-row gap-[1rem] items-center">
      <div className="p-[0.5rem] border-[1px] rounded-[0.25rem] border-neutral-20 w-[3.5rem] h-[3.5rem] flex flex-row items-center justify-center">
        <img src={iconSrc} alt="cash" className="w-[2rem]" />
      </div>
      <div className="flex flex-col flex-1">
        <p className="body-large text-neutral-90 font-semibold">{title}</p>
        <p className="body-small text-neutral-60">{message}</p>
      </div>
    </div>
  );
};

export default CardMessage;
