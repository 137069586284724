import { getToken } from "firebase/messaging";
import { createContext, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { messaging, onMessageListener } from "../firebase";
import { setFCM } from "../redux/actions";
import fcmTopicEnum from "../utils/fcmTopicEnum";
import Popup from "../components/Popup";
import NotificationIcon from "../assets/img/notification-bell.svg";
import { useApi } from "../api";

const vapidKey =
  "BDqzwh95jMKQk4DhM7HT8siDjS9NRZPte7eWdhTTcICQokVbqcyYNmVRMPwncQX4IdOlJ0xvEinAvydNXUIevK0";

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const dispatch = useDispatch();
  const api = useApi();

  const { fcmToken, loggedIn, clinicDetails } = useSelector(
    (state) => state.userReducer
  );

  const [notifications, setNotifications] = useState([]);
  const [notificationsWithoutPopup, setNotificationsWithoutPopup] = useState(
    []
  );

  const showNotification = (notification) => {
    if (
      notification.data.msg === fcmTopicEnum.WAIVE_FEE ||
      notification.data.msg === fcmTopicEnum.REQUEST_NEXT_PATIENT ||
      notification.data.msg === fcmTopicEnum.SEND_NEXT_PATIENT
    ) {
      setNotifications((notifications) => [...notifications, notification]);
    } else {
      setNotificationsWithoutPopup((notificationsWithoutPopup) => [
        ...notificationsWithoutPopup,
        notification,
      ]);
    }
  };

  const showNotificationsFromApi = (notificationsArray) => {
    const notifications = [];
    const notificationsWithoutPopup = [];
    for (const n of notificationsArray) {
      if (
        n.msg === fcmTopicEnum.WAIVE_FEE ||
        n.msg === fcmTopicEnum.REQUEST_NEXT_PATIENT ||
        n.msg === fcmTopicEnum.SEND_NEXT_PATIENT
      ) {
        notifications.push({ data: n });
      } else {
        notificationsWithoutPopup.push({ data: n });
      }
    }
    setNotifications((n) => [...n, ...notifications]);
    setNotificationsWithoutPopup((n) => [...n, ...notificationsWithoutPopup]);
  };

  const dismissNotification = () => {
    setNotifications((notifications) => {
      const newNotifications = [...notifications];
      newNotifications.shift();
      return newNotifications;
    });
  };

  onMessageListener()
    .then((payload) => {
      showNotification(payload);
    })
    .catch(console.log);

  const requestNotifications = async () => {
    if ("Notification" in window) {
      let permission = Notification.permission;

      if (permission === "default") {
        permission = await Notification.requestPermission();
      }

      if (permission === "granted") {
        const token = await getToken(messaging, { vapidKey });
        dispatch(setFCM(token));
      } else {
        dispatch(setFCM(null));
      }
    } else {
      dispatch(setFCM(null));
      alert("Notifications not supported");
    }
  };

  const isNotFcm = clinicDetails.some((item) => !item.isFcm);

  useEffect(() => {
    let interval;
    const shouldApplyInterval = loggedIn && isNotFcm;

    if (shouldApplyInterval) {
      interval = setInterval(() => {
        api
          .getNotifications()
          .then((res) => {
            const notifs = res.data.body;
            showNotificationsFromApi(notifs);
          })
          .catch((error) => {
            console.log(error);
          });
      }, 5 * 1000);
    } else {
      clearInterval(interval);
    }

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line
  }, [loggedIn, fcmToken, isNotFcm]);

  useEffect(() => {
    requestNotifications();
    // eslint-disable-next-line
  }, []);

  const currentPopupNotification = notifications.at(0);

  return (
    <NotificationContext.Provider
      value={{ notifications, notificationsWithoutPopup }}
    >
      {children}
      {currentPopupNotification &&
        (currentPopupNotification.data.msg ===
        fcmTopicEnum.REQUEST_NEXT_PATIENT ? (
          <Popup icon={NotificationIcon} hide={dismissNotification} visible>
            <h4 className="heading-4 font-bold">Next Patient</h4>
            <p className="body-small text-neutral-60">
              Doctor has requested to send next patient
            </p>
          </Popup>
        ) : currentPopupNotification.data.msg === fcmTopicEnum.WAIVE_FEE ? (
          <Popup icon={NotificationIcon} hide={dismissNotification} visible>
            <h4 className="heading-4 font-bold">Fee waived</h4>
            <p className="body-small text-neutral-60">
              Doctor has waived Rs. {currentPopupNotification.data.waiveAmount}{" "}
              for current patient
            </p>
          </Popup>
        ) : (
          <></>
        ))}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => {
  const notifications = useContext(NotificationContext);
  return notifications;
};

export default NotificationProvider;
