import { useState } from "react";
import { useApi } from "../api";
import getErrorMessage from "../utils/getErrorMessage";

const useSubscription = () => {
  const api = useApi();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetch = () => {
    setLoading(loading);
    setError(error);
    api
      .getPackages()
      .then((res) => {
        const data = res.data.body;
        setData(data);
      })
      .catch((err) => {
        const message = getErrorMessage(err);
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    fetch,
    data,
    loading,
    error,
  };
};

export default useSubscription;
