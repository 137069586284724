import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useApi } from "../api";
import { useSnackbar } from "../context/Snackbar.context";
import getErrorMessage from "../utils/getErrorMessage";
import { PhoneRegex } from "../utils/regex";
import useGetPatients from "./useGetPatients";

const useCreatePatient = () => {
  const api = useApi();

  const params = useParams();
  const snackbar = useSnackbar();
  const navigate = useNavigate();
  const { role } = useSelector((state) => state.userReducer);

  const isDoctor = role === "Doctor";

  let clinicID;

  if (!isDoctor) {
    clinicID = params.clinicID;
  }

  const getPatients = useGetPatients(clinicID);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const form = useFormik({
    initialValues: {
      cellNumber: "",
      patientName: "",
      gender: "",
      age: "",
    },
    isInitialValid: false,
    validateOnChange: true,
    validationSchema: Yup.object().shape({
      cellNumber: Yup.string()
        .required("Cell number is required")
        .matches(PhoneRegex, { message: "Invalid cell number" }),
      patientName: Yup.string()
        .required("Patient name is required")
        .min(3, "Patient name must have minimum 3 characters"),
      gender: Yup.string()
        .required("Gender is required")
        .oneOf(["male", "female"], "Invalid gender"),
      age: Yup.number().required("Age is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      (isDoctor
        ? api.registerAddPatient({ countryCode: "+92", ...values })
        : api.registerAddPatient({ clinicID, countryCode: "+92", ...values })
      )
        .then(() => {
          resetForm();
          snackbar.show("error", "Patient added");
          if (clinicID) {
            navigate("/pa", { state: { clinicID }, replace: true });
          } else {
            navigate(-1);
          }
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          setError(message);
          snackbar.show("error", message);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const { cellNumber } = form.values;

  const isPhoneValid = PhoneRegex.test(cellNumber);

  useEffect(() => {
    if (isPhoneValid) {
      getPatients.search(`+92${cellNumber}`);
    } else {
      getPatients.reset();
    }
    // eslint-disable-next-line
  }, [cellNumber]);

  const addPatientQueue = ({ clinicID, patientID }) => {
    setLoading(true);
    (isDoctor
      ? api.addPatientQueue({ patientID })
      : api.addPatientQueue({ clinicID, patientID })
    )
      .then(() => {
        snackbar.show("success", "Patient Added");
        if (clinicID) {
          navigate("/pa", { state: { clinicID }, replace: true });
        } else {
          navigate(-1);
        }
      })
      .catch((error) => {
        console.log(error);
        const message = getErrorMessage(error);
        snackbar.show(
          "error",
          message === "unique violation" ? "Patient already in queue" : message
        );
        setError(message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    loading,
    error,
    form,
    getPatients: {
      ...getPatients,
      patients:
        !isPhoneValid || !getPatients.searchValue ? [] : getPatients.patients,
    },
    addPatientQueue,
  };
};

export default useCreatePatient;
