import React, { useState } from "react";
import Button from "../../components/Button";
import PhoneInput from "../../components/PhoneInput";
import ScreenLayout from "../../components/ScreenLayout";
import SelectInput from "../../components/SelectInput";
import Popup from "../../components/Popup";
import TextInput from "../../components/TextInput";
import useAssignPA from "../../hooks/useAssignPA";
import { genders } from "../../utils/constants";
import IconButton from "../../components/IconButton";
import DeleteIcon from "../../assets/img/delete.svg";
import ReplaceIcon from "../../assets/img/replace.svg";

const AssignRole = () => {
  const assignPA = useAssignPA();

  const { paDetails } = assignPA;

  const paExist = paDetails?.paExist;
  const fullName = paDetails?.fullName;

  const [confirmingReplace, setConfirmingReplace] = useState(false);
  const [confirmingDelete, setConfirmingDelete] = useState(false);

  return (
    <ScreenLayout title="Assign Role">
      <div className="flex flex-col gap-[1.5rem]">
        <div className="bg-white border-neutral-20 border-[1px] p-[1rem] rounded-[0.5rem] flex flex-row gap-[0.5rem] w-full">
          <div className="flex flex-col w-full justify-between gap-[0.5rem]">
            <h6 className="heading-6 text-neutral-90 font-semibold">
              Assigned PA
            </h6>
            <h3 className="heading-3 text-neutral-90">
              {paExist ? fullName : "None"}
            </h3>
            {/* {paExist && (
              <p className="body-small text-neutral-60 font-semibold">
                {cellNumber}
              </p>
            )} */}
          </div>
          {paExist && (
            <IconButton
              variant="tertiary"
              iconSrc={DeleteIcon}
              alt="remove-pa"
              onClick={() => {
                setConfirmingDelete(true);
              }}
            />
          )}
        </div>
        <PhoneInput
          required
          name="cellNumber"
          label="Cell Number"
          value={assignPA.form.values.cellNumber}
          error={assignPA.form.errors.cellNumber}
          setValue={assignPA.form.handleChange("cellNumber")}
          onFocus={() => assignPA.form.setFieldTouched("cellNumber", true)}
          onBlur={assignPA.form.handleBlur("cellNumber")}
          touched={assignPA.form.touched.cellNumber}
        />
        <TextInput
          placeholder="Enter name"
          required
          label="Name"
          name="fullName"
          value={assignPA.form.values.fullName}
          isAlphabetic
          error={assignPA.form.errors.fullName}
          setValue={assignPA.form.handleChange("fullName")}
          onBlur={assignPA.form.handleBlur("fullName")}
          onFocus={() => assignPA.form.setFieldTouched("fullName", true)}
          touched={assignPA.form.touched.fullName}
        />
        <SelectInput
          placeholder="--Select gender--"
          {...genders}
          required
          name="gender"
          value={assignPA.form.values.gender}
          error={assignPA.form.errors.gender}
          setValue={assignPA.form.handleChange("gender")}
          onBlur={assignPA.form.handleBlur("gender")}
          onFocus={() => assignPA.form.setFieldTouched("gender", true)}
          touched={assignPA.form.touched.gender}
          label="Gender"
        />
        <div className="mt-[2rem] flex flex-col gap-[0.5rem]">
          <Button
            text={paExist ? "Replace PA" : "Assign PA"}
            fullWidth
            size="btn-xlarge"
            disabled={assignPA.loading || !assignPA.form.isValid}
            onClick={
              paExist
                ? () => setConfirmingReplace(true)
                : assignPA.form.handleSubmit
            }
          />
        </div>
      </div>
      <Popup
        visible={confirmingReplace}
        hide={() => {
          setConfirmingReplace(false);
        }}
        icon={ReplaceIcon}
      >
        <h4 className="heading-4 font-bold">Replace PA</h4>
        <p className="body-small text-neutral-60">
          Are you sure you want to replace PA?
        </p>
        <div className="flex flex-row justify-row gap-[1rem] w-full">
          <Button
            text="Cancel"
            fullWidth
            size="btn-large"
            variant="btn-secondary"
            onClick={() => {
              setConfirmingReplace(false);
            }}
          />
          <Button
            text="Confirm"
            fullWidth
            size="btn-large"
            onClick={
              paExist
                ? () => {
                    assignPA.form.handleSubmit();
                    setConfirmingReplace(false);
                  }
                : undefined
            }
            disabled={assignPA.loading || !assignPA.form.isValid}
          />
        </div>
      </Popup>

      <Popup
        visible={confirmingDelete}
        hide={() => {
          setConfirmingDelete(false);
        }}
        icon={DeleteIcon}
      >
        <h4 className="heading-4 font-bold">Delete PA</h4>
        <p className="body-small text-neutral-60">
          Are you sure you want to delete PA?
        </p>
        <div className="flex flex-row justify-row gap-[1rem] w-full">
          <Button
            text="Cancel"
            fullWidth
            size="btn-large"
            variant="btn-secondary"
            onClick={() => {
              setConfirmingDelete(false);
            }}
          />
          <Button
            text="Confirm"
            fullWidth
            size="btn-large"
            onClick={
              paExist
                ? () => {
                    assignPA.deletePA();
                    setConfirmingDelete(false);
                  }
                : undefined
            }
            disabled={assignPA.loading}
          />
        </div>
      </Popup>
    </ScreenLayout>
  );
};

export default AssignRole;
